<template>
  <div>
    <!-- Page: KYC -->
    <div class="d-flex">
      <h4 class="mb-1">
        {{ $t('organization.kyc.title') }}
      </h4>
    </div>

    <b-alert variant="warning" show>
      <div class="alert-body">
        <feather-icon icon="AlertTriangleIcon" class="mr-50" />
        {{ $t('organization.kyc.changes_warning') }}
      </div>
    </b-alert>

    <b-button :disabled="!kycAccountLink" target="_blank" :href="kycAccountLink">
      {{ $t('organization.kyc.account_link') }}
    </b-button>
    <!--    <validation-observer ref="KYCsRules" tag="form" @submit.prevent="onSubmit">
      <b-overlay class="my-3" :show="loadOrganizationKYC" spinner-medium>
        &lt;!&ndash; -&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45; Account -&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45; &ndash;&gt;

        <div v-if="KYCrequirements.account && KYCrequirements.account.length !== 0" class="d-flex">
          <h4 class="mb-1">
            {{ $t('organization.kyc.account.title') }}
          </h4>
        </div>
        <b-row v-if="KYCrequirements.account && KYCrequirements.account.company !== undefined" class="mb-2">
          &lt;!&ndash; Name &ndash;&gt;
          <b-col v-if="KYCrequirements.account.company.name !== undefined" cols="12" md="6" lg="6">
            <validation-provider #default="{ errors }" :name="$t('organization.kyc.account.name')" rules="required" class="validation-required">
              <b-form-group :label="$t('organization.kyc.account.name')" label-for="companyName">
                <b-form-input
                  id="companyName"
                  v-model="KYCrequirements.account.company.name"
                  :placeholder="$t('organization.kyc.account.name')"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          &lt;!&ndash; Name Kana &ndash;&gt;
          <b-col v-if="KYCrequirements.account.company.name_kana !== undefined" cols="12" md="6" lg="6">
            <validation-provider #default="{ errors }" :name="$t('organization.kyc.account.name_kana')" rules="required" class="validation-required">
              <b-form-group :label="$t('organization.kyc.account.name_kana')" label-for="companyNameKana">
                <b-form-input
                  id="companyNameKana"
                  v-model="KYCrequirements.account.company.name_kana"
                  :placeholder="$t('organization.kyc.account.name_kana')"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          &lt;!&ndash; Name Kanji &ndash;&gt;
          <b-col v-if="KYCrequirements.account.company.name_kanji !== undefined" cols="12" md="6" lg="6">
            <validation-provider #default="{ errors }" :name="$t('organization.kyc.account.name_kanji')" rules="required" class="validation-required">
              <b-form-group :label="$t('organization.kyc.account.name_kanji')" label-for="companyNameKanji">
                <b-form-input
                  id="companyNameKanji"
                  v-model="KYCrequirements.account.company.name_kanji"
                  :placeholder="$t('organization.kyc.account.name_kanji')"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          &lt;!&ndash; Structure &ndash;&gt;
          <b-col v-if="KYCrequirements.account.company.structure !== undefined" cols="12" md="6" lg="6">
            <validation-provider #default="{ errors }" :name="$t('organization.kyc.account.structure')" rules="required" class="validation-required">
              <b-form-group :label="$t('organization.kyc.account.structure')" label-for="companyStructure">
                <b-form-input
                  id="companyStructure"
                  v-model="KYCrequirements.account.company.structure"
                  :placeholder="$t('organization.kyc.account.structure')"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          &lt;!&ndash; Address &ndash;&gt;
          <b-col
            v-if="KYCrequirements.account.company.address !== undefined && KYCrequirements.account.company.address.line1 !== undefined"
            cols="12"
            md="6"
            lg="6"
          >
            <validation-provider #default="{ errors }" :name="$t('organization.kyc.account.address.address')" rules="required" class="validation-required">
              <b-form-group :label="$t('organization.kyc.account.address.address')" label-for="companyAddress">
                <b-form-input
                  id="companyAddress"
                  v-model="KYCrequirements.account.company.address.line1"
                  :placeholder="$t('organization.kyc.account.address.address')"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            v-if="KYCrequirements.account.company.address !== undefined && KYCrequirements.account.company.address.postal_code !== undefined"
            cols="12"
            md="6"
            lg="6"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('organization.kyc.account.address.postal_code')"
              rules="required"
              class="validation-required"
            >
              <b-form-group :label="$t('organization.kyc.account.address.postal_code')" label-for="companyPostalCode">
                <b-form-input
                  id="companyPostalCode"
                  v-model="KYCrequirements.account.company.address.postal_code"
                  :placeholder="$t('organization.kyc.account.address.postal_code')"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            v-if="KYCrequirements.account.company.address !== undefined && KYCrequirements.account.company.address.city !== undefined"
            cols="12"
            md="6"
            lg="6"
          >
            <validation-provider #default="{ errors }" :name="$t('organization.kyc.account.address.city')" rules="required" class="validation-required">
              <b-form-group :label="$t('organization.kyc.account.address.city')" label-for="companyCity">
                <b-form-input
                  id="companyCity"
                  v-model="KYCrequirements.account.company.address.city"
                  :placeholder="$t('organization.kyc.account.address.city')"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            v-if="KYCrequirements.account.company.address !== undefined && KYCrequirements.account.company.address.state !== undefined"
            cols="12"
            md="6"
            lg="6"
          >
            <validation-provider #default="{ errors }" :name="$t('organization.kyc.account.address.state')" rules="required" class="validation-required">
              <b-form-group :label="$t('organization.kyc.account.address.state')" label-for="companyState">
                <b-form-input
                  id="companyState"
                  v-model="KYCrequirements.account.company.address.state"
                  :placeholder="$t('organization.kyc.account.address.state')"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          &lt;!&ndash; Address Kana &ndash;&gt;
          <b-col
            v-if="KYCrequirements.account.company.address_kana !== undefined && KYCrequirements.account.company.address_kana.line1 !== undefined"
            cols="12"
            md="6"
            lg="6"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('organization.kyc.account.address_kana.address')"
              rules="required"
              class="validation-required"
            >
              <b-form-group :label="$t('organization.kyc.account.address_kana.address')" label-for="companyAddressKana">
                <b-form-input
                  id="companyAddressKana"
                  v-model="KYCrequirements.account.company.address_kana.line1"
                  :placeholder="$t('organization.kyc.account.address_kana.address')"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            v-if="KYCrequirements.account.company.address_kana !== undefined && KYCrequirements.account.company.address_kana.postal_code !== undefined"
            cols="12"
            md="6"
            lg="6"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('organization.kyc.account.address_kana.postal_code')"
              rules="required"
              class="validation-required"
            >
              <b-form-group :label="$t('organization.kyc.account.address_kana.postal_code')" label-for="companyPostalCodeKana">
                <b-form-input
                  id="companyPostalCodeKana"
                  v-model="KYCrequirements.account.company.address_kana.postal_code"
                  :placeholder="$t('organization.kyc.account.address_kana.postal_code')"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            v-if="KYCrequirements.account.company.address_kana !== undefined && KYCrequirements.account.company.address_kana.city !== undefined"
            cols="12"
            md="6"
            lg="6"
          >
            <validation-provider #default="{ errors }" :name="$t('organization.kyc.account.address_kana.city')" rules="required" class="validation-required">
              <b-form-group :label="$t('organization.kyc.account.address_kana.city')" label-for="companyCityKana">
                <b-form-input
                  id="companyCityKana"
                  v-model="KYCrequirements.account.company.address_kana.city"
                  :placeholder="$t('organization.kyc.account.address_kana.city')"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            v-if="KYCrequirements.account.company.address_kana !== undefined && KYCrequirements.account.company.address_kana.town !== undefined"
            cols="12"
            md="6"
            lg="6"
          >
            <validation-provider #default="{ errors }" :name="$t('organization.kyc.account.address_kana.town')" rules="required" class="validation-required">
              <b-form-group :label="$t('organization.kyc.account.address_kana.town')" label-for="companyTownKana">
                <b-form-input
                  id="companyTownKana"
                  v-model="KYCrequirements.account.company.address_kana.town"
                  :placeholder="$t('organization.kyc.company.address_kana.town')"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            v-if="KYCrequirements.account.company.address_kana !== undefined && KYCrequirements.account.company.address_kana.state !== undefined"
            cols="12"
            md="6"
            lg="6"
          >
            <validation-provider #default="{ errors }" :name="$t('organization.kyc.account.address_kana.state')" rules="required" class="validation-required">
              <b-form-group :label="$t('organization.kyc.account.address_kana.state')" label-for="companyStateKana">
                <b-form-input
                  id="companyStateKana"
                  v-model="KYCrequirements.account.company.address_kana.state"
                  :placeholder="$t('organization.kyc.company.address_kana.state')"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          &lt;!&ndash; Address Kanji &ndash;&gt;
          <b-col
            v-if="KYCrequirements.account.company.address_kanji !== undefined && KYCrequirements.account.company.address_kanji.line1 !== undefined"
            cols="12"
            md="6"
            lg="6"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('organization.kyc.account.address_kanji.address')"
              rules="required"
              class="validation-required"
            >
              <b-form-group :label="$t('organization.kyc.account.address_kanji.address')" label-for="companyAddressKanji">
                <b-form-input
                  id="companyAddressKanji"
                  v-model="KYCrequirements.account.company.address_kanji.line1"
                  :placeholder="$t('organization.kyc.account.address_kanji.address')"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            v-if="KYCrequirements.account.company.address_kanji !== undefined && KYCrequirements.account.company.address_kanji.postal_code !== undefined"
            cols="12"
            md="6"
            lg="6"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('organization.kyc.account.address_kanji.postal_code')"
              rules="required"
              class="validation-required"
            >
              <b-form-group :label="$t('organization.kyc.account.address_kanji.postal_code')" label-for="companyPostalCodeKanji">
                <b-form-input
                  id="companyPostalCodeKanji"
                  v-model="KYCrequirements.account.company.address_kanji.postal_code"
                  :placeholder="$t('organization.kyc.account.address_kanji.postal_code')"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            v-if="KYCrequirements.account.company.address_kanji !== undefined && KYCrequirements.account.company.address_kanji.city !== undefined"
            cols="12"
            md="6"
            lg="6"
          >
            <validation-provider #default="{ errors }" :name="$t('organization.kyc.account.address_kanji.city')" rules="required" class="validation-required">
              <b-form-group :label="$t('organization.kyc.account.address_kanji.city')" label-for="companyCityKanji">
                <b-form-input
                  id="companyCityKanji"
                  v-model="KYCrequirements.account.company.address_kanji.city"
                  :placeholder="$t('organization.kyc.account.address_kanji.city')"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            v-if="KYCrequirements.account.company.address_kanji !== undefined && KYCrequirements.account.company.address_kanji.town !== undefined"
            cols="12"
            md="6"
            lg="6"
          >
            <validation-provider #default="{ errors }" :name="$t('organization.kyc.account.address_kanji.town')" rules="required" class="validation-required">
              <b-form-group :label="$t('organization.kyc.account.address_kanji.town')" label-for="companyTownKanji">
                <b-form-input
                  id="companyTownKanji"
                  v-model="KYCrequirements.account.company.address_kanji.town"
                  :placeholder="$t('organization.kyc.account.address_kanji.town')"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            v-if="KYCrequirements.account.company.address_kanji !== undefined && KYCrequirements.account.company.address_kanji.state !== undefined"
            cols="12"
            md="6"
            lg="6"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('organization.kyc.account.address_kanji.state')"
              rules="required"
              class="validation-required"
            >
              <b-form-group :label="$t('organization.kyc.account.address_kanji.state')" label-for="companyStateKanji">
                <b-form-input
                  id="companyStateKanji"
                  v-model="KYCrequirements.account.company.address_kanji.state"
                  :placeholder="$t('organization.kyc.account.address_kanji.state')"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          &lt;!&ndash; Phone &ndash;&gt;
          <b-col v-if="KYCrequirements.account.company.phone !== undefined" cols="12" md="6" lg="6">
            <validation-provider #default="{ errors }" :name="$t('organization.kyc.account.phone')" rules="required" class="validation-required">
              <b-form-group :label="$t('organization.kyc.account.phone')" label-for="companyPhone">
                <vue-tel-input
                  v-model="KYCrequirements.account.company.phone"
                  :class="phoneCompanyObj && !phoneCompanyObj.valid && KYCrequirements.account.company.phone ? 'is-invalid' : null"
                  class="form-control"
                  :default-country="external_account.country"
                  :state="errors.length > 0 ? false : null"
                  @validate="val => (phoneCompanyObj = val)"
                />
                <small v-if="phoneCompanyObj && !phoneCompanyObj.valid && KYCrequirements.account.company.phone" class="text-danger">{{
                  $t('validator.invalid_tel.message')
                }}</small>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          &lt;!&ndash; Check &ndash;&gt;
          <b-col
            v-if="
              KYCrequirements.account.company.owners_provided !== undefined ||
                KYCrequirements.account.company.directors_provided !== undefined ||
                KYCrequirements.account.company.executives_provided !== undefined
            "
            cols="12"
            md="6"
            lg="6"
          >
            <b-form-group v-if="KYCrequirements.account.company.owners_provided !== undefined" label-for="ownersProvided">
              <b-form-checkbox v-model="KYCrequirements.account.company.owners_provided">
                {{ $t('organization.kyc.account.owners_provided') }}
              </b-form-checkbox>
            </b-form-group>
            <b-form-group v-if="KYCrequirements.account.company.directors_provided !== undefined" label-for="directorsProvided">
              <b-form-checkbox v-model="KYCrequirements.account.company.directors_provided">
                {{ $t('organization.kyc.account.directors_provided') }}
              </b-form-checkbox>
            </b-form-group>
            <b-form-group v-if="KYCrequirements.account.company.executives_provided !== undefined" label-for="executivesProvided">
              <b-form-checkbox v-model="KYCrequirements.account.company.executives_provided">
                {{ $t('organization.kyc.account.executives_provided') }}
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          &lt;!&ndash; Tax Id &ndash;&gt;
          <b-col v-if="KYCrequirements.account.company.tax_id !== undefined" cols="12" md="6" lg="6">
            <validation-provider #default="{ errors }" :name="$t('organization.kyc.account.tax_id')" rules="required" class="validation-required">
              <b-form-group :label="$t('organization.kyc.account.tax_id')" label-for="companyTaxId">
                <b-form-input
                  id="companyTaxId"
                  v-model="KYCrequirements.account.company.tax_id"
                  :placeholder="$t('organization.kyc.account.tax_id')"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          &lt;!&ndash; Registration number &ndash;&gt;
          <b-col v-if="KYCrequirements.account.company.registration_number !== undefined" cols="12" md="6" lg="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('organization.kyc.account.registration_number')"
              rules="required"
              class="validation-required"
            >
              <b-form-group :label="$t('organization.kyc.account.registration_number')" label-for="companyRegistrationNumber">
                <b-form-input
                  id="companyRegistrationNumber"
                  v-model="KYCrequirements.account.company.registration_number"
                  :placeholder="$t('organization.kyc.account.registration_number')"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        &lt;!&ndash; -&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45; PERSON -&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45; &ndash;&gt;
        <div v-if="KYCrequirements.person && KYCrequirements.person.length !== 0" class="d-flex">
          <h4 class="mb-1">
            {{ $t('organization.kyc.person.title') }}
          </h4>
        </div>
        <b-row v-if="KYCrequirements.person && KYCrequirements.person.length !== 0" class="mb-2">
          &lt;!&ndash; Last Name &ndash;&gt;
          <b-col v-if="KYCrequirements.person.last_name !== undefined" cols="12" md="6" lg="6">
            <validation-provider #default="{ errors }" :name="$t('organization.kyc.person.last_name')" rules="required" class="validation-required">
              <b-form-group :label="$t('organization.kyc.person.last_name')" label-for="lastName">
                <b-form-input
                  id="lastName"
                  v-model="KYCrequirements.person.last_name"
                  :placeholder="$t('organization.kyc.person.last_name')"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          &lt;!&ndash; Last Name Kana &ndash;&gt;
          <b-col v-if="KYCrequirements.person.last_name_kana !== undefined" cols="12" md="6" lg="6">
            <validation-provider #default="{ errors }" :name="$t('organization.kyc.person.last_name_kana')" rules="required" class="validation-required">
              <b-form-group :label="$t('organization.kyc.person.last_name_kana')" label-for="lastNameKana">
                <b-form-input
                  id="lastNameKana"
                  v-model="KYCrequirements.person.last_name_kana"
                  :placeholder="$t('organization.kyc.person.last_name_kana')"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          &lt;!&ndash; Last Name Kanji &ndash;&gt;
          <b-col v-if="KYCrequirements.person.last_name_kanji !== undefined" cols="12" md="6" lg="6">
            <validation-provider #default="{ errors }" :name="$t('organization.kyc.person.last_name_kanji')" rules="required" class="validation-required">
              <b-form-group :label="$t('organization.kyc.person.last_name_kanji')" label-for="lastNameKanji">
                <b-form-input
                  id="lastNameKanji"
                  v-model="KYCrequirements.person.last_name_kanji"
                  :placeholder="$t('organization.kyc.person.last_name_kanji')"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          &lt;!&ndash; first Name&ndash;&gt;
          <b-col v-if="KYCrequirements.person.first_name !== undefined" cols="12" md="6" lg="6">
            <validation-provider #default="{ errors }" :name="$t('organization.kyc.person.first_name')" rules="required" class="validation-required">
              <b-form-group :label="$t('organization.kyc.person.first_name')" label-for="firstName">
                <b-form-input
                  id="firstName"
                  v-model="KYCrequirements.person.first_name"
                  :placeholder="$t('organization.kyc.person.first_name')"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          &lt;!&ndash; first Name Kana&ndash;&gt;
          <b-col v-if="KYCrequirements.person.first_name_kana !== undefined" cols="12" md="6" lg="6">
            <validation-provider #default="{ errors }" :name="$t('organization.kyc.person.first_name_kana')" rules="required" class="validation-required">
              <b-form-group :label="$t('organization.kyc.person.first_name_kana')" label-for="firstNameKana">
                <b-form-input
                  id="firstNameKana"
                  v-model="KYCrequirements.person.first_name_kana"
                  :placeholder="$t('organization.kyc.person.first_name_kana')"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          &lt;!&ndash; first Name Kanji&ndash;&gt;
          <b-col v-if="KYCrequirements.person.first_name_kanji !== undefined" cols="12" md="6" lg="6">
            <validation-provider #default="{ errors }" :name="$t('organization.kyc.person.first_name_kanji')" rules="required" class="validation-required">
              <b-form-group :label="$t('organization.kyc.person.first_name_kanji')" label-for="firstNameKanji">
                <b-form-input
                  id="firstNameKanji"
                  v-model="KYCrequirements.person.first_name_kanji"
                  :placeholder="$t('organization.kyc.person.first_name_kanji')"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          &lt;!&ndash; address &ndash;&gt;
          <b-col v-if="KYCrequirements.person.address !== undefined && KYCrequirements.person.address.line1 !== undefined" cols="12" md="6" lg="6">
            <validation-provider #default="{ errors }" :name="$t('organization.kyc.person.address.address')" rules="required" class="validation-required">
              <b-form-group :label="$t('organization.kyc.person.address.address')" label-for="personAddress">
                <b-form-input
                  id="personAddress"
                  v-model="KYCrequirements.person.address.line1"
                  :placeholder="$t('organization.kyc.person.address.address')"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col v-if="KYCrequirements.person.address !== undefined && KYCrequirements.person.address.postal_code !== undefined" cols="12" md="6" lg="6">
            <validation-provider #default="{ errors }" :name="$t('organization.kyc.person.address.postal_code')" rules="required" class="validation-required">
              <b-form-group :label="$t('organization.kyc.person.address.postal_code')" label-for="personAddressPostalCode">
                <b-form-input
                  id="personAddressPostalCode"
                  v-model="KYCrequirements.person.address.postal_code"
                  :placeholder="$t('organization.kyc.person.address.postal_code')"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col v-if="KYCrequirements.person.address !== undefined && KYCrequirements.person.address.state !== undefined" cols="12" md="6" lg="6">
            <validation-provider #default="{ errors }" :name="$t('organization.kyc.person.address.state')" rules="required" class="validation-required">
              <b-form-group :label="$t('organization.kyc.person.address.state')" label-for="personAddressState">
                <b-form-input
                  id="personAddressState"
                  v-model="KYCrequirements.person.address.state"
                  :placeholder="$t('organization.kyc.person.address.state')"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col v-if="KYCrequirements.person.address !== undefined && KYCrequirements.person.address.city !== undefined" cols="12" md="6" lg="6">
            <validation-provider #default="{ errors }" :name="$t('organization.kyc.person.address.city')" rules="required" class="validation-required">
              <b-form-group :label="$t('organization.kyc.person.address.city')" label-for="personAddressTown">
                <b-form-input
                  id="personAddressCity"
                  v-model="KYCrequirements.person.address.city"
                  :placeholder="$t('organization.kyc.person.address.city')"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          &lt;!&ndash; address Kana &ndash;&gt;
          <b-col v-if="KYCrequirements.person.address_kana !== undefined && KYCrequirements.person.address_kana.line1 !== undefined" cols="12" md="6" lg="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('organization.kyc.person.address_kana.address')"
              rules="required"
              class="validation-required"
            >
              <b-form-group :label="$t('organization.kyc.person.address_kana.address')" label-for="personAddressKana">
                <b-form-input
                  id="personAddressKana"
                  v-model="KYCrequirements.person.address_kana.line1"
                  :placeholder="$t('organization.kyc.person.address_kana.line1')"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            v-if="KYCrequirements.person.address_kana !== undefined && KYCrequirements.person.address_kana.postal_code !== undefined"
            cols="12"
            md="6"
            lg="6"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('organization.kyc.person.address_kana.postal_code')"
              rules="required"
              class="validation-required"
            >
              <b-form-group :label="$t('organization.kyc.person.address_kana.postal_code')" label-for="personAddressKanaPostalCode">
                <b-form-input
                  id="personAddressKanaPostalCode"
                  v-model="KYCrequirements.person.address_kana.postal_code"
                  :placeholder="$t('organization.kyc.person.address_kana.postal_code')"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col v-if="KYCrequirements.person.address_kana !== undefined && KYCrequirements.person.address_kana.state !== undefined" cols="12" md="6" lg="6">
            <validation-provider #default="{ errors }" :name="$t('organization.kyc.person.address_kana.state')" rules="required" class="validation-required">
              <b-form-group :label="$t('organization.kyc.person.address_kana.state')" label-for="personAddressKanaState">
                <b-form-input
                  id="personAddressKanaState"
                  v-model="KYCrequirements.person.address_kana.state"
                  :placeholder="$t('organization.kyc.person.address_kana.state')"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col v-if="KYCrequirements.person.address_kana !== undefined && KYCrequirements.person.address_kana.town !== undefined" cols="12" md="6" lg="6">
            <validation-provider #default="{ errors }" :name="$t('organization.kyc.person.address_kana.town')" rules="required" class="validation-required">
              <b-form-group :label="$t('organization.kyc.person.address_kana.town')" label-for="personAddressKanaTown">
                <b-form-input
                  id="personAddressKanaTown"
                  v-model="KYCrequirements.person.address_kana.town"
                  :placeholder="$t('organization.kyc.person.address_kana.town')"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col v-if="KYCrequirements.person.address_kana !== undefined && KYCrequirements.person.address_kana.city !== undefined" cols="12" md="6" lg="6">
            <validation-provider #default="{ errors }" :name="$t('organization.kyc.person.address_kana.city')" rules="required" class="validation-required">
              <b-form-group :label="$t('organization.kyc.person.address_kana.city')" label-for="personAddressKanaTown">
                <b-form-input
                  id="personAddressKanaCity"
                  v-model="KYCrequirements.person.address_kana.city"
                  :placeholder="$t('organization.kyc.person.address_kana.city')"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          &lt;!&ndash; address Kanji &ndash;&gt;
          <b-col
            v-if="KYCrequirements.person.address_kanji !== undefined && KYCrequirements.person.address_kanji.line1 !== undefined"
            cols="12"
            md="6"
            lg="6"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('organization.kyc.person.address_kanji.address')"
              rules="required"
              class="validation-required"
            >
              <b-form-group :label="$t('organization.kyc.person.address_kanji.address')" label-for="personAddressKanji">
                <b-form-input
                  id="personAddressKanji"
                  v-model="KYCrequirements.person.address_kanji.line1"
                  :placeholder="$t('organization.kyc.person.address_kanji.line1')"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            v-if="KYCrequirements.person.address_kanji !== undefined && KYCrequirements.person.address_kanji.postal_code !== undefined"
            cols="12"
            md="6"
            lg="6"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('organization.kyc.person.address_kanji.postal_code')"
              rules="required"
              class="validation-required"
            >
              <b-form-group :label="$t('organization.kyc.person.address_kanji.postal_code')" label-for="personAddressKanjiPostalCode">
                <b-form-input
                  id="personAddressKanjiPostalCode"
                  v-model="KYCrequirements.person.address_kanji.postal_code"
                  :placeholder="$t('organization.kyc.person.address_kanji.postal_code')"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            v-if="KYCrequirements.person.address_kanji !== undefined && KYCrequirements.person.address_kanji.state !== undefined"
            cols="12"
            md="6"
            lg="6"
          >
            <validation-provider #default="{ errors }" :name="$t('organization.kyc.person.address_kanji.state')" rules="required" class="validation-required">
              <b-form-group :label="$t('organization.kyc.person.address_kanji.state')" label-for="personAddressKanjiState">
                <b-form-input
                  id="personAddressKanjiState"
                  v-model="KYCrequirements.person.address_kanji.state"
                  :placeholder="$t('organization.kyc.person.address_kanji.state')"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col v-if="KYCrequirements.person.address_kanji !== undefined && KYCrequirements.person.address_kanji.town !== undefined" cols="12" md="6" lg="6">
            <validation-provider #default="{ errors }" :name="$t('organization.kyc.person.address_kanji.town')" rules="required" class="validation-required">
              <b-form-group :label="$t('organization.kyc.person.address_kanji.town')" label-for="personAddressKanjiTown">
                <b-form-input
                  id="personAddressKanjiTown"
                  v-model="KYCrequirements.person.address_kanji.town"
                  :placeholder="$t('organization.kyc.person.address_kanji.town')"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col v-if="KYCrequirements.person.address_kanji !== undefined && KYCrequirements.person.address_kanji.city !== undefined" cols="12" md="6" lg="6">
            <validation-provider #default="{ errors }" :name="$t('organization.kyc.person.address_kanji.city')" rules="required" class="validation-required">
              <b-form-group :label="$t('organization.kyc.person.address_kanji.city')" label-for="personAddressKanjiTown">
                <b-form-input
                  id="personAddressKanjiCity"
                  v-model="KYCrequirements.person.address_kanji.city"
                  :placeholder="$t('organization.kyc.person.address_kanji.city')"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          &lt;!&ndash; DOB &ndash;&gt;
          <b-col v-if="KYCrequirements.person.dob !== undefined" cols="12" md="6" lg="6">
            <validation-provider #default="{ errors }" :name="$t('organization.kyc.person.nationality')" rules="required" class="validation-required">
              <b-form-group :label="$t('organization.kyc.person.dob')" label-for="birthdate">
                <flat-pickr
                  id="birthdate"
                  v-model="birthday"
                  size="md"
                  class="mb-1"
                  :placeholder="$t('organization.kyc.person.dob')"
                  :config="{
                    altInput: true,
                    altFormat: 'D, F J Y',
                    dateFormat: 'Y-m-d',
                    localize: $i18n.locale
                  }"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          &lt;!&ndash; Nationality &ndash;&gt;
          <b-col v-if="KYCrequirements.person.nationality !== undefined" cols="12" md="6" lg="6">
            <validation-provider #default="{ errors }" :name="$t('organization.kyc.person.nationality')" rules="required" class="validation-required">
              <b-form-group :label="$t('organization.kyc.person.nationality')" label-for="personNationality">
                <b-form-input
                  id="personNationality"
                  v-model="KYCrequirements.person.nationality"
                  :placeholder="$t('organization.kyc.person.nationality')"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          &lt;!&ndash; Phone &ndash;&gt;
          <b-col v-if="KYCrequirements.person.phone !== undefined" cols="12" md="6" lg="6">
            <validation-provider #default="{ errors }" :name="$t('organization.kyc.person.phone')" rules="required" class="validation-required">
              <b-form-group :label="$t('organization.kyc.person.phone')" label-for="personPhone">
                <vue-tel-input
                  v-model="KYCrequirements.person.phone"
                  :class="phonePersonObj && !phonePersonObj.valid && KYCrequirements.person.phone ? 'is-invalid' : null"
                  class="form-control"
                  :default-country="external_account.country"
                  :state="errors.length > 0 ? false : null"
                  @validate="val => (phonePersonObj = val)"
                />
                <small v-if="phonePersonObj && !phonePersonObj.valid && KYCrequirements.person.phone" class="text-danger">{{
                  $t('validator.invalid_tel.message')
                }}</small>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          &lt;!&ndash; email &ndash;&gt;
          <b-col v-if="KYCrequirements.person.email !== undefined" cols="12" md="6" lg="6">
            <validation-provider #default="{ errors }" :name="$t('organization.kyc.person.email')" rules="required|email" class="validation-required">
              <b-form-group :label="$t('organization.kyc.person.email')" label-for="personEmail">
                <b-form-input
                  id="personEmail"
                  v-model="KYCrequirements.person.email"
                  :placeholder="$t('organization.kyc.person.email')"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          &lt;!&ndash; ID number &ndash;&gt;
          <b-col v-if="KYCrequirements.person.id_number !== undefined" cols="12" md="6" lg="6">
            <validation-provider #default="{ errors }" :name="$t('organization.kyc.person.id_number')" rules="required" class="validation-required">
              <b-form-group :label="$t('organization.kyc.person.id_number')" label-for="personId">
                <b-form-input
                  id="personId"
                  v-model="KYCrequirements.person.id_number"
                  :placeholder="$t('organization.kyc.person.id_number')"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          &lt;!&ndash; Full name aliases &ndash;&gt;
          <b-col v-if="KYCrequirements.person.full_name_aliases !== undefined" cols="12" md="6" lg="6">
            <validation-provider #default="{ errors }" :name="$t('organization.kyc.person.full_name_aliases')" rules="required" class="validation-required">
              <b-form-group :label="$t('organization.kyc.person.full_name_aliases')" label-for="personFullNameAliases">
                <b-form-input
                  id="personFullNameAliases"
                  v-model="KYCrequirements.person.full_name_aliases"
                  :placeholder="$t('organization.kyc.person.full_name_aliases')"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          &lt;!&ndash; Political exposure &ndash;&gt;
          <b-col v-if="KYCrequirements.person.political_exposure !== undefined" cols="12" md="6" lg="6">
            <validation-provider #default="{ errors }" :name="$t('organization.kyc.person.political_exposure')" rules="required" class="validation-required">
              <b-form-group :label="$t('organization.kyc.person.political_exposure')" label-for="personPoliticalExposure">
                <b-form-input
                  id="personPoliticalExposure"
                  v-model="KYCrequirements.person.political_exposure"
                  :placeholder="$t('organization.kyc.person.political_exposure')"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          &lt;!&ndash; SSN last 4 &ndash;&gt;
          <b-col v-if="KYCrequirements.person.ssn_last_4 !== undefined" cols="12" md="6" lg="6">
            <validation-provider #default="{ errors }" :name="$t('organization.kyc.person.ssn_last_4')" rules="required" class="validation-required">
              <b-form-group :label="$t('organization.kyc.person.ssn_last_4')" label-for="personSSNLast4">
                <b-form-input
                  id="personSSNLast4"
                  v-model="KYCrequirements.person.ssn_last_4"
                  :placeholder="$t('organization.kyc.person.ssn_last_4')"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          &lt;!&ndash; RelationShip &ndash;&gt;
          <b-col v-if="KYCrequirements.person.relationship !== undefined && KYCrequirements.person.relationship.owner !== undefined" cols="12" md="6" lg="6">
            <b-form-group label-for="owner">
              <b-form-checkbox v-model="KYCrequirements.person.relationship.owner">
                {{ $t('organization.kyc.person.relationship.owner') }}
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col
            v-if="KYCrequirements.person.relationship !== undefined && KYCrequirements.person.relationship.percent_ownership !== undefined"
            cols="12"
            md="6"
            lg="6"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('organization.kyc.person.relationship.percent_ownership')"
              rules="required"
              class="validation-required"
            >
              <b-form-group :label="$t('organization.kyc.person.relationship.percent_ownership')" label-for="personPercentOwnership">
                <b-form-input
                  id="personPercentOwnership"
                  v-model="KYCrequirements.person.relationship.percent_ownership"
                  :placeholder="$t('organization.kyc.person.relationship.percent_ownership')"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            v-if="KYCrequirements.person.relationship !== undefined && KYCrequirements.person.relationship.executive !== undefined"
            cols="12"
            md="6"
            lg="6"
          >
            <b-form-group label-for="owner">
              <b-form-checkbox v-model="KYCrequirements.person.relationship.executive">
                {{ $t('organization.kyc.person.relationship.executive') }}
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col v-if="KYCrequirements.person.relationship !== undefined && KYCrequirements.person.relationship.title !== undefined" cols="12" md="6" lg="6">
            <validation-provider #default="{ errors }" :name="$t('organization.kyc.person.relationship.title')" rules="required" class="validation-required">
              <b-form-group :label="$t('organization.kyc.person.relationship.title')" label-for="personRelationshipTitle">
                <b-form-input
                  id="personRelationshipTitle"
                  v-model="KYCrequirements.person.relationship.title"
                  :placeholder="$t('organization.kyc.person.relationship.title')"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            v-if="KYCrequirements.person.relationship !== undefined && KYCrequirements.person.relationship.director !== undefined"
            cols="12"
            md="6"
            lg="6"
          >
            <b-form-group label-for="director">
              <b-form-checkbox v-model="KYCrequirements.person.relationship.director">
                {{ $t('organization.kyc.person.relationship.director') }}
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col
            v-if="KYCrequirements.person.relationship !== undefined && KYCrequirements.person.relationship.representative !== undefined"
            cols="12"
            md="6"
            lg="6"
          >
            <b-form-group label-for="representative">
              <b-form-checkbox v-model="KYCrequirements.person.relationship.representative">
                {{ $t('organization.kyc.person.relationship.representative') }}
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          &lt;!&ndash; Document &ndash;&gt;
          <b-col
            v-if="KYCrequirements.person.verification !== undefined && KYCrequirements.person.verification.document !== undefined"
            cols="12"
            md="12"
            lg="12"
          >
            <label for="documentFront">{{ $t('organization.kyc.person.verification.document_front') }}</label><br />
            <input id="documentFront" type="file" name="documentFront" accept=".jpeg,.jpg,.png,.pdf" /><br />
            <label for="documentBack">{{ $t('organization.kyc.person.verification.document_back') }}</label><br />
            <input id="documentBack" type="file" name="documentBack" accept=".jpeg,.jpg,.png,.pdf" /><br />
          </b-col>
          &lt;!&ndash; Additional document &ndash;&gt;
          <b-col
            v-if="KYCrequirements.person.verification !== undefined && KYCrequirements.person.verification.additional_document !== undefined"
            cols="12"
            md="12"
            lg="12"
          >
            <label for="documentAddFront">{{ $t('organization.kyc.person.verification.additional_document_front') }}</label><br />
            <input id="documentAddFront" type="file" name="documentAddFront" accept=".jpeg,.jpg,.png,.pdf" /><br />
            <label for="documentAddBack">{{ $t('organization.kyc.person.verification.additional_document_back') }}</label><br />
            <input id="documentAddBack" type="file" name="documentAddBack" accept=".jpeg,.jpg,.png,.pdf" /><br />
          </b-col>
          &lt;!&ndash; Passport &ndash;&gt;
          <b-col v-if="KYCrequirements.person.documents !== undefined && KYCrequirements.person.documents.passport !== undefined" cols="12" md="12" lg="12">
            <label for="passportFront">{{ $t('organization.kyc.person.documents.passport_front') }}</label><br />
            <input id="passportFront" type="file" name="passportFront" accept=".jpeg,.jpg,.png,.pdf" /><br />
            <label for="passportBack">{{ $t('organization.kyc.person.documents.passport_back') }}</label><br />
            <input id="passportBack" type="file" name="passportBack" accept=".jpeg,.jpg,.png,.pdf" /><br />
          </b-col>
        </b-row>

        &lt;!&ndash; -&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45; Other-&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;  &ndash;&gt;
        <div v-if="KYCrequirements.other && KYCrequirements.other.length !== 0" class="d-flex">
          <h4 class="mb-1">
            {{ $t('organization.kyc.other.titleOther') }}
          </h4>
        </div>
        <b-row v-if="KYCrequirements.other && KYCrequirements.other.length !== 0" class="mb-2">
          &lt;!&ndash; MCC &ndash;&gt;
          <b-col v-if="KYCrequirements.other.business_profile !== undefined && KYCrequirements.other.business_profile.mcc !== undefined" cols="12">
            <enum-select
              v-model="KYCrequirements.other.business_profile.mcc"
              :label="$t('organization.kyc.other.business_profile.mcc')"
              enum-class="MerchantCategory"
              required
            />
          </b-col>
          &lt;!&ndash; Production description &ndash;&gt;
          <b-col
            v-if="KYCrequirements.other.business_profile !== undefined && KYCrequirements.other.business_profile.product_description !== undefined"
            cols="12"
            md="6"
            lg="6"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('organization.kyc.other.business_profile.product_description')"
              rules="required"
              class="validation-required"
            >
              <b-form-group :label="$t('organization.kyc.other.business_profile.product_description')" label-for="product_description">
                <b-form-input
                  id="product_description"
                  v-model="KYCrequirements.other.business_profile.product_description"
                  :placeholder="$t('organization.kyc.other.business_profile.product_description')"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          &lt;!&ndash; Support Phone &ndash;&gt;
          <b-col v-if="KYCrequirements.other.business_profile !== undefined && KYCrequirements.other.business_profile.support_phone !== undefined" cols="12">
            <validation-provider
              #default="{ errors }"
              :name="$t('organization.kyc.other.business_profile.support_phone')"
              rules="required"
              class="validation-required"
            >
              <b-form-group :label="$t('organization.kyc.other.business_profile.support_phone')" label-for="supportPhone">
                <vue-tel-input
                  v-model="KYCrequirements.other.business_profile.support_phone"
                  :class="supportPhoneObj && !supportPhoneObj.valid && KYCrequirements.other.business_profile.support_phone ? 'is-invalid' : null"
                  class="form-control"
                  :default-country="external_account.country"
                  :state="errors.length > 0 ? false : null"
                  @validate="val => (supportPhoneObj = val)"
                />
                <small v-if="supportPhoneObj && !supportPhoneObj.valid && KYCrequirements.other.business_profile.support_phone" class="text-danger">{{
                  $t('validator.invalid_tel.message')
                }}</small>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          &lt;!&ndash; URL &ndash;&gt;
          <b-col v-if="KYCrequirements.other.business_profile !== undefined && KYCrequirements.other.business_profile.url !== undefined" cols="12">
            <b-form-group :label="$t('organization.kyc.other.business_profile.url')" label-for="url">
              <b-form-input id="url" v-model="KYCrequirements.other.business_profile.url" :placeholder="$t('organization.kyc.other.business_profile.url')" />
            </b-form-group>
          </b-col>
        </b-row>

        &lt;!&ndash; External Account &ndash;&gt;
        <div v-if="KYCrequirements.other && KYCrequirements.other.length !== 0">
          <h5 v-if="KYCrequirements.other.external_account !== undefined" class="mb-1">
            {{ $t('organization.kyc.bank_coordinates') }}
          </h5>
        </div>
        <b-row v-if="KYCrequirements.other && KYCrequirements.other.external_account !== undefined" class="mb-2">
          &lt;!&ndash; Routing Number &ndash;&gt;
          <b-col cols="12">
            <b-form-group :label="$t('organization.kyc.other.external_account.routing_number')" label-for="routingNumber">
              <b-form-input
                id="routingNumber"
                v-model="external_account.routing_number"
                :placeholder="$t('organization.kyc.other.external_account.routing_number')"
              />
            </b-form-group>
          </b-col>
          &lt;!&ndash; Account Number &ndash;&gt;
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              :name="$t('organization.kyc.other.external_account.account_number')"
              rules="required"
              class="validation-required"
            >
              <b-form-group :label="$t('organization.kyc.other.external_account.account_number')" label-for="accountNumber">
                <b-form-input
                  id="accountNumber"
                  v-model="external_account.account_number"
                  :placeholder="$t('organization.kyc.other.external_account.account_number')"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          &lt;!&ndash; Account holder name &ndash;&gt;
          <b-col cols="12">
            <b-form-group :label="$t('organization.kyc.other.external_account.account_holder_name')" label-for="accountHolderName">
              <b-form-input
                id="accountHolderName"
                v-model="external_account.account_holder_name"
                :placeholder="$t('organization.kyc.other.external_account.account_holder_name')"
              />
            </b-form-group>
          </b-col>
          &lt;!&ndash; Account holder type &ndash;&gt;
          <b-col cols="12">
            <b-form-group :label="$t('organization.kyc.other.external_account.account_holder_type')" label-for="accountHolderType">
              <b-form-input
                id="accountHolderType"
                v-model="external_account.account_holder_type"
                :placeholder="$t('organization.kyc.other.external_account.account_holder_type')"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="KYCrequirements.account && KYCrequirements.account.length !== 0">
          &lt;!&ndash; Tos shown and accepted &ndash;&gt;
          <b-col v-if="KYCrequirements.account.tos_shown_and_accepted !== undefined" cols="12" md="6" lg="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('organization.kyc.account.tos_shown_and_accepted')"
              rules="required"
              class="validation-required"
            >
              <label for="tosAccepted">
                <b-link @click="openStripeTos">{{ $t('organization.kyc.account.tos_shown_and_accepted') }}</b-link>
              </label>
              <b-form-group class="d-inline-block">
                <b-form-checkbox id="tosAccepted" v-model="KYCrequirements.account.tos_shown_and_accepted" class="pl-1 red" name="check-button" switch inline />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        &lt;!&ndash; FOOTER &ndash;&gt;
        <div class="d-flex mt-2">
          <b-button :disabled="lockSubmit" @click="onSubmit">
            <font-awesome-icon icon="plus" class="mr-50" />
            {{ $t('action.submit') }}
          </b-button>
        </div>
      </b-overlay>

    </validation-observer>-->

    <app-form-wizard-footer class="mt-3" previous next @click:previous="$emit('previous-tab')" @click:next="$emit('next-tab')" />
  </div>
</template>

<script>
import stripe from '@/libs/stripes'
import { mapActions, mapState } from 'vuex'
import { fetchOrganizationKYCAccountLinkRequest } from '@/request/globalApi/requests/organizationRequests'
import AppFormWizardFooter from '@/components/AppFormWizardFooter.vue'

export default {
  name: 'KYC',

  components: {
    AppFormWizardFooter,
  },
  props: {
    organizationData: {
      type: Object,
      default: () => ({}),
    },
    organizationId: {
      type: [String, Number],
      required: true,
      default: '',
    },
  },
  data() {
    return {
      nextRedirection: false,
      loadOrganizationKYC: true,
      loadOrganizationKYCAccountLink: false,
      kycAccountLink: null,
      KYCrequ: {},
      KYCrequirements: {},
      phonePersonObj: null,
      phoneCompanyObj: null,
      supportPhoneObj: null,
      elements: null,
      tosValue: false,
      appearance: { theme: 'stripe' },
      file_token: '',
      external_account: {
        country: '',
        currency: '',
        routing_number: '',
        account_number: '',
        account_holder_name: '',
        account_holder_type: '',
      },
      KYCinfo: {
        accountToken: '',
        personToken: '',
        account: {
          business_profile: {},
          external_account: '',
        },
      },
      birthday: '',
      lockSubmit: false,
    }
  },
  computed: {
    ...mapState('accounting', ['filtersOptions', 'filtersOptionsLoading', 'itemsLoading', 'itemStateLoading', 'itemState']),
    ...mapState('enum', ['MerchantCategory']),
    ...mapState('auth', ['selectedOrganization']),
  },
  mounted() {
    this.APIFetchStripeIntent()
    this.fetchEnum2('MerchantCategory')
  },
  methods: {
    ...mapActions('stripe', ['fetchStripeIntent']),
    ...mapActions('enum', ['fetchEnum2']),
    APIFetchOrganizationKYCAccountLink() {
      this.loadOrganizationKYCAccountLink = true
      this.stripe
        .createToken('account', {
          business_type: 'company',
          account: {
            tos_shown_and_accepted: true,
          },
        })
        .then(result => {
          if (result.error || !result.token) {
            this.toaster(result.error.message, 'danger', 'AlertTriangleIcon')
          } else {
            const params = {
              refreshUrl: window.location.href,
              returnUrl: window.location.href,
              accountToken: result.token.id,
            }

            fetchOrganizationKYCAccountLinkRequest(this.organizationId, params).then(response => {
              this.kycAccountLink = response.data.url
            }).finally(() => {
              this.loadOrganizationKYCAccountLink = false
            })
          }
        })
        .catch(() => false)
    },
    APIFetchStripeIntent() {
      stripe.then(response => {
        this.stripe = response
        this.APIFetchOrganizationKYCAccountLink()
      })
    },
    /* async APIFetchOrganizationKYC() {
      this.loadOrganizationKYC = true
      await fetchOrganizationKYCRequest(this.organizationId).then(response => {
        const { requirements } = response.data
        const { country } = response.data
        this.KYCrequ = requirements
        this.external_account.country = country.code

        this.formatInformations()
      })
      this.loadOrganizationKYC = false
    },
    formatInformations() {
      this.KYCrequirements = this._cloneDeep(this.KYCrequ)

      if (this.KYCrequirements.account.company) {
        for (const company of Object.entries(this.KYCrequirements.account.company)) {
          if (company[1] === 'text') {
            this.KYCrequirements.account.company[company[0]] = ''
          }

          if (company[1] === 'boolean') {
            this.KYCrequirements.account.company[company[0]] = true
          }

          if (typeof company[1] === 'object') {
            for (const prop of Object.entries(this.KYCrequirements.account.company[company[0]])) {
              if (prop[1] === 'text') {
                this.KYCrequirements.account.company[company[0]][prop[0]] = ''
              }
            }
          }
        }
      }

      if (this.KYCrequirements.person) {
        for (const person of Object.entries(this.KYCrequirements.person)) {
          if (person[1] === 'text' || person[1] === 'mail') {
            this.KYCrequirements.person[person[0]] = ''
          }

          if (person[1] === 'boolean') {
            this.KYCrequirements.person[person[0]] = true
          }

          if (typeof person[1] === 'object') {
            for (const prop of Object.entries(this.KYCrequirements.person[person[0]])) {
              if (prop[1] === 'text') {
                this.KYCrequirements.person[person[0]][prop[0]] = ''
              }
              if (prop[1] === 'boolean') {
                this.KYCrequirements.person[person[0]][prop[0]] = true
              }
            }
          }
        }
      }

      if (this.KYCrequirements.other.business_profile) {
        for (const other of Object.entries(this.KYCrequirements.other.business_profile)) {
          if (other[1] === 'text') {
            this.KYCrequirements.other.business_profile[other[0]] = ''
          }
          if (other[1] === 'stripe_merchant_category_id') {
            this.KYCrequirements.other.business_profile[other[0]] = ''
          }
        }
      }

      if (this.KYCrequirements.account.business_type) {
        this.KYCrequirements.account.business_type = 'company'
      }
    },
    async getTokenPII() {
      await this.stripe.createToken('pii', { personel_id_number: person.id_number }).then(result => {
        if (result.token) {
          this.KYCrequirements.person.id_number = result.token.id
        }
        if (result.error) {
          this.toaster(result.error.message, 'danger', 'AlertTriangleIcon')
        }
      })
    },
    async getTokenExternalAccount() {
      const bankAccountParameter = {
        country: this.external_account.country,
        currency: this.$store.state.appConfig.currency,
        routing_number: this.external_account.routing_number,
        account_number: this.external_account.account_number,
        account_holder_name: this.external_account.account_holder_name,
        account_holder_type: this.external_account.account_holder_type,
      }

      if (this.external_account.routing_number === '') {
        delete bankAccountParameter.routing_number
      }

      await this.stripe
        .createToken('bank_account', bankAccountParameter)
        .then(result => {
          if (result.token) {
            this.KYCinfo.account.external_account = result.token.id
          }

          if (result.error) {
            this.toaster(result.error.message, 'danger', 'AlertTriangleIcon')
          }
        })
        .catch(() => false)
    },
    async fileUpload(fileId) {
      this.fileToken = ''
      if (document.querySelector(fileId).files[0] !== undefined) {
        const data = new FormData()
        data.append('file', document.querySelector(fileId).files[0])
        data.append('purpose', 'identity_document')
        const fileResult = await fetch('https://uploads.stripe.com/v1/files', {
          method: 'POST',
          headers: { Authorization: `Bearer ${this.stripe._apiKey}` },
          body: data,
        })
        const fileData = await fileResult.json()
        this.fileToken = fileData.id
      }
    },
    async onSubmit() {
      let rulesValides = false
      await this.$refs.KYCsRules.validate().then(result => {
        if (result === true) {
          const TOS = document.getElementById('#tosAccepted')
          if (this.KYCrequirements.account !== undefined && this.KYCrequirements.account.tos_shown_and_accepted !== undefined) {
            if (this.KYCrequirements.account.tos_shown_and_accepted === true) {
              rulesValides = result
            } else {
              this.toaster(this.$t('organization.kyc.account.tosError'), 'danger', 'AlertTriangleIcon')
            }
          } else {
            rulesValides = result
          }
          if (this.nextRedirection) {
            this.$emit('next-tab')
            this.nextRedirection = false
          }
        }
      })

      if (rulesValides) {
        this.lockSubmit = true
        if (this.KYCrequirements.person.id_number) {
          await this.getTokenPII()
        }

        if (this.KYCrequirements.other.external_account) {
          await this.getTokenExternalAccount()
        } else {
          delete this.KYCinfo.account.external_account
        }

        if (this.KYCrequirements.person.verification !== undefined && this.KYCrequirements.person.verification.document !== undefined) {
          await this.fileUpload('#documentFront')
          this.KYCrequirements.person.verification.document.front = this.fileToken
          await this.fileUpload('#documentBack')
          this.KYCrequirements.person.verification.document.back = this.fileToken
        }

        if (this.KYCrequirements.person.verification !== undefined && this.KYCrequirements.person.verification.additional_document !== undefined) {
          await this.fileUpload('#documentAddFront')
          this.KYCrequirements.person.verification.additional_document.front = this.fileToken
          await this.fileUpload('#documentAddBack')
          this.KYCrequirements.person.verification.additional_document.back = this.fileToken
        }

        if (this.KYCrequirements.person.documents !== undefined && this.KYCrequirements.person.documents.passport !== undefined) {
          await this.fileUpload('#documentAddFront')
          this.KYCrequirements.person.documents.passport.files.front = this.fileToken
          await this.fileUpload('#documentAddBack')
          this.KYCrequirements.person.documents.passport.files.back = this.fileToken
        }

        if (
          (this.phonePersonObj && (this.phonePersonObj.number === undefined || !this.phonePersonObj.valid))
          || (this.phoneCompanyObj && (this.phoneCompanyObj.number === undefined || !this.phoneCompanyObj.valid))
          || (this.supportPhoneObj && (this.supportPhoneObj.number === undefined || !this.supportPhoneObj.valid))
        ) {
          this.toaster(this.$t('validator.invalid_tel.message'), 'danger', 'AlertTriangleIcon')
          return false
        }

        if (this.phonePersonObj && this.phonePersonObj.nationalNumber) {
          this.KYCrequirements.person.phone = `+${this.phonePersonObj.countryCallingCode}${this.phonePersonObj.nationalNumber}`
        }

        if (this.phoneCompanyObj && this.phoneCompanyObj.nationalNumber) {
          this.KYCrequirements.account.company.phone = `+${this.phoneCompanyObj.countryCallingCode}${this.phoneCompanyObj.nationalNumber}`
        }

        if (this.supportPhoneObj && this.supportPhoneObj.nationalNumber) {
          this.KYCrequirements.other.business_profile.support_phone = `+${this.supportPhoneObj.countryCallingCode}${this.supportPhoneObj.nationalNumber}`
        }

        // birthday
        if (this.KYCrequirements.person.dob) {
          const DOB = this.birthday.split('-')
          this.KYCrequirements.person.dob = {
            day: DOB[2],
            month: DOB[1],
            year: DOB[0],
          }
        }

        if (this.KYCrequirements.other.business_profile !== undefined) {
          this.KYCinfo.account.business_profile = this.KYCrequirements.other.business_profile
        } else {
          delete this.KYCinfo.account
        }

        await this.stripe
          .createToken('account', this.KYCrequirements.account)
          .then(result => {
            if (result.token) {
              this.KYCinfo.accountToken = result.token.id
            }

            if (result.error) {
              this.toaster(result.error.message, 'danger', 'AlertTriangleIcon')
            }
          })
          .catch(() => false)

        if (this.KYCinfo.accountToken === '') {
          delete this.KYCinfo.accountToken
        }

        await this.stripe
          .createToken('person', this.KYCrequirements.person)
          .then(result => {
            this.lockSubmit = false
            if (result.token) {
              this.KYCinfo.personToken = result.token.id
            }

            if (result.error) {
              this.toaster(result.error.message, 'danger', 'AlertTriangleIcon')
            }
          })
          .catch(() => false)

        if (this.KYCinfo.personToken === '') {
          delete this.KYCinfo.personToken
        }

        if (this.KYCinfo.account.business_profile) {
          if (this.KYCinfo.account.business_profile.url === '' || this.KYCinfo.account.business_profile.url === 'text') {
            delete this.KYCinfo.account.business_profile.url
          }
        }

        await patchOrganizationKYCRequest(this.organizationId, this.KYCinfo)
          .then(result => {
            this.refetchData()
            this.lockSubmit = false
            if (result.error) {
              this.toaster(result.error.message, 'danger', 'AlertTriangleIcon')
            }
          })
          .catch(() => false)
      }
    },
    async refetchData() {
      await this.APIFetchOrganizationKYC()
    },
    openStripeTos() {
      window.open('https://stripe.com/fr/ssa', '_blank')
    }, */
  },
}
</script>
