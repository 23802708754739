<template>
  <app-sidebar-form
    form-name="external-account"
    :edition="showSidebarExternalAccount"
    :edition-mode-title="externalAccountMode"
    :disabled="externalAccountLoading"
    :title="$t('organization.bank_accounts.title')"
    :entity="externalAccount"
    no-close-on-submit
    @update:edition="$emit('editionUpdated', $event)"
    @update:entity="$emit('externalAccountUpdated', $event)"
  >
    <template #fields="data">
      <enum-select
        id="external-account-country"
        v-model="data.item.externalAccount.countryCode"
        :overlay="externalAccountLoading"
        :disabled="externalAccountMode === 'edit'"
        enum-class="CountryCode"
        :label="$t('common.country')"
        :clearable="false"
        text="text"
        :required="externalAccountMode === 'add'"
      />
      <enum-select
        id="external-account-currency-edit"
        v-model="data.item.externalAccount.currencyCode"
        :overlay="externalAccountLoading"
        :disabled="externalAccountMode === 'edit'"
        :label="$t('pricing.currency')"
        enum-class="CurrencyCode"
        :clearable="false"
        :required="externalAccountMode === 'add'"
      />
      <app-input
        v-if="externalAccountMode === 'edit'"
        id="external-account-account-number"
        v-model="data.item.externalAccount.lastCharacters"
        :overlay="externalAccountLoading"
        :disabled="true"
        :label="$t('organization.kyc.other.external_account.account_number')"
      />
      <app-input
        v-if="externalAccountMode === 'add'"
        id="external-account-account-number"
        v-model="data.item.stripeExternalAccount.account_number"
        :overlay="externalAccountLoading"
        :label="$t('organization.kyc.other.external_account.account_number')"
        required
      />
      <app-input
        v-if="data.item.externalAccount.currencyCode && data.item.externalAccount.currencyCode !== 'EUR'"
        id="external-account-routing-number"
        v-model="data.item.stripeExternalAccount.routing_number"
        :label="$t('organization.kyc.other.external_account.routing_number')"
        rules="numeric|length:9"
        required
      />
      <app-input
        id="external-account-account-holder-name"
        v-model="data.item.stripeExternalAccount.account_holder_name"
        :overlay="externalAccountLoading"
        :label="$t('organization.kyc.other.external_account.account_holder_name')"
      />
      <validation-provider #default="{ errors }" :name="$t('organization.main_informations.default_currency')" class="validation-required" rules="required">
        <b-form-checkbox v-show="externalAccountMode === 'edit'" v-model="data.item.externalAccount.defaultForCurrency" :disabled="externalAccountLoading">
          {{ $t('organization.main_informations.default_currency') }}
        </b-form-checkbox>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </template>
  </app-sidebar-form>
</template>

<script>
import AppSidebarForm from '@/components/AppSidebarForm.vue'

export default {
  name: 'SidebarExternalAccount',

  components: {
    AppSidebarForm,
  },
  props: {
    showSidebarExternalAccount: {
      type: Boolean,
      required: true,
    },
    externalAccountMode: {
      type: String,
      default: 'edit',
    },
    externalAccount: {
      type: Object,
      default: null,
    },
    externalAccountLoading: {
      type: Boolean,
      required: true,
    },
  },
}
</script>
