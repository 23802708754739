/* eslint-disable class-methods-use-this */

import BaseModel from './_Base'
import { editOrganizationInvoiceConfigurationRequest } from '@/request/globalApi/requests/organizationRequests'

/**
 * InvoiceConfiguration
 * @link https://vuemc.io/#basic-usage
 */
class InvoiceConfiguration extends BaseModel {
  // Default attributes that define the "empty" state.
  defaults() {
    return {
      id: null,
      address: {
        address: '',
        complementaryAddress: '',
        postalCode: '',
        city: '',
        country: null,
      },
      prefix: '',
      referenceFormat: '',
      footer: '',
      compositionItems: [
        {
          invoiceCompositionItemType: '',
        },
      ],
    }
  }

  // Attribute mutations to normalize data.
  mutations() {
    return {
      id: id => id || null,
      address: Object,
      prefix: String,
      referenceFormat: String,
      footer: String,
      compositionItems: Array,
    }
  }

  // ? NOTE: Which validation library to choose?
  // Attribute validation, acts after mutations

  // TODO
  // validation() {
  //   return {

  //   }
  // }

  //--------------------------------
  //        API REQUESTS
  //--------------------------------

  patch(invoiceConfigurationId) {
    const compositionItems = []
    this.compositionItems.forEach(item => compositionItems.push(item))
    const payload = {
      id: invoiceConfigurationId,
      address: {
        address: this.address.address,
        complementaryAddress: this.address.complementaryAddress,
        postalCode: this.address.postalCode,
        city: this.address.city,
        country: this.address.country,
      },
      prefix: this.prefix,
      referenceFormat: this.referenceFormat,
      footer: this.footer,
      compositionItems,
    }
    return editOrganizationInvoiceConfigurationRequest(payload)
  }
}

export default InvoiceConfiguration
