<template>
  <div id="external-accounts">
    <b-card class="mb-0" no-body>
      <b-row>
        <!-- KYC -->
        <b-col cols="12" md="6">
          <b-button @click="redirectKycAccountLink()">
            {{ $t('organization.kyc.account_link') }}
          </b-button>
        </b-col>
      </b-row>

      <app-data-table
        ref="external-accounts-table"
        table-name="external-accounts-table"
        primary-key="id"
        :fields="organizationExternalAccountsFields"
        :items="organizationExternalAccounts"
        :busy="organizationExternalAccountsLoading"
        :page="page"
        :per-page="numberOfItemsPerPage"
        manual-pagination
        :top-table="{
          showSearch: false,
          showAdd: $can('ORGANIZATION_ACCOUNT_EDIT'),
          disabled: organizationExternalAccountsLoading,
        }"
        :actions-table="{
          showEdit: $can('ORGANIZATION_ACCOUNT_EDIT'),
          showDelete: item => $can('ORGANIZATION_ACCOUNT_DELETE') && !item.defaultForCurrency,
        }"
        :bottom-table="{ totalCount: pagination.totalItems }"
        class="mx-n2"
        @table-select-per-page=";(numberOfItemsPerPage = $event), fetchOrganizationExternalAccounts({ refresh: true, organizationId: this.organizationId })"
        @table-add="addExternalAccount()"
        @edit="editExternalAccount($event.item.id)"
        @delete="deleteOrganizationExternalAccount($event.item)"
        @pagination=";(page = $event), fetchOrganizationExternalAccounts({ refresh: true, organizationId: this.organizationId })"
      >
        <!--CELL: Status-->
        <template #cell(lastCharacters)="data">
          {{ `**** ${data.item.lastCharacters}` }}
        </template>
        <template #cell(defaultForCurrency)="data">
          <span v-if="data.item.defaultForCurrency"> {{ $t('common.yes') }}</span>
          <span v-else> {{ $t('common.no') }}</span>
        </template>
        <!--CELL: Status-->
        <template #cell(status)="data">
          {{ data.item.status | enumTranslate('external_account_status') }}
        </template>
      </app-data-table>
    </b-card>

    <app-form-wizard-footer
      class="mt-3 mx-n1"
      next
      previous
      @click:previous="$emit('previous-tab')"
      @click:next="$emit('next-tab')"
    />

    <!--SIDEBAR-->
    <SidebarExternalAccount
      :show-sidebar-external-account="showSidebarExternalAccount"
      :external-account-mode="externalAccountMode"
      :external-account="externalAccount"
      :external-account-loading="organizationExternalAccountLoading"
      @editionUpdated="showSidebarExternalAccount = $event"
      @externalAccountUpdated="saveExternalAccount($event)"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import {
  deleteOrganizationExternalAccountRequest,
  fetchOrganizationExternalAccountRequest,
  fetchOrganizationKYCAccountLinkRequest,
  patchOrganizationExternalAccountRequest,
  postOrganizationExternalAccountRequest,
} from '@/request/globalApi/requests/organizationRequests'

import stripe from '@/libs/stripes'

import AppDataTable from '@/components/AppDataTable.vue'
import AppFormWizardFooter from '@/components/AppFormWizardFooter.vue'
import SidebarExternalAccount from '@/views/organization/sidebar/SidebarExternalAccount.vue'

export default {
  name: 'BankAccounts',
  components: {
    AppDataTable,
    SidebarExternalAccount,
    AppFormWizardFooter,
  },

  props: {
    seller: {
      type: Boolean,
      default: false,
    },
    operator: {
      type: Boolean,
      default: false,
    },
    organizationId: {
      type: [String, Number],
      required: true,
      default: null,
    },
  },
  data() {
    return {
      showSidebarExternalAccount: false,
      externalAccountMode: '',
      externalAccount: null,
      organizationExternalAccountLoading: false,
    }
  },

  computed: {
    ...mapGetters('auth', ['selectedOrganizationId']),
    ...mapState('organizationExternalAccount', ['organizationExternalAccounts', 'pagination']),
    ...mapFields('organizationExternalAccount', ['queryParams.page', 'queryParams.numberOfItemsPerPage', 'organizationExternalAccountsLoading']),

    organizationExternalAccountsFields() {
      return [
        { key: 'bankName', label: this.$t('organization.bank_accounts.bank_name'), sortable: true },
        { key: 'lastCharacters', label: this.$t('organization.bank_accounts.last_characters'), sortable: true },
        { key: 'currencyCode', label: this.$t('pricing.currency'), sortable: true },
        { key: 'defaultForCurrency', label: this.$t('organization.bank_accounts.default_for_currency'), sortable: true },
        { key: 'status', label: this.$t('common.status') },
      ]
    },
  },

  mounted() {
    this.fetchOrganizationExternalAccounts(
      {
        refresh: this.$route.params.refresh || false,
        organizationId: this.organizationId,
      },
    )
  },

  methods: {
    ...mapActions('organizationExternalAccount', ['fetchOrganizationExternalAccounts']),

    redirectKycAccountLink() {
      stripe.then(response => {
        this.stripe = response
        this.APIFetchOrganizationKYCAccountLink()
      })
    },
    APIFetchOrganizationKYCAccountLink() {
      this.loadOrganizationKYCAccountLink = true
      this.stripe
        .createToken('account', {
          business_type: 'company',
          account: {
            tos_shown_and_accepted: true,
          },
        })
        .then(result => {
          if (result.error || !result.token) {
            this.toaster(result.error.message, 'danger', 'AlertTriangleIcon')
          } else {
            const params = {
              refreshUrl: window.location.href,
              returnUrl: window.location.href,
              accountToken: result.token.id,
            }

            fetchOrganizationKYCAccountLinkRequest(this.organizationId, params).then(response => {
              window.open(response.data.url)
            }).finally(() => {
              this.loadOrganizationKYCAccountLink = false
            })
          }
        })
        .catch(() => false)
    },

    deleteOrganizationExternalAccount(externalAccount) {
      deleteOrganizationExternalAccountRequest(this.organizationId, externalAccount.id, externalAccount.bankName)
        .then(response => {
          response
            && this.fetchOrganizationExternalAccounts({
              refresh: true,
              organizationId: this.organizationId,
            }).finally(() => {
              this.resetExternalAccount()
            })
        })
        .catch(err => {
          if (err) {
            this.resetExternalAccount()
          }
        })
    },

    addExternalAccount() {
      this.externalAccount = {
        externalAccount: {
          defaultForCurrency: true,
          country: '',
          currency: '',
        },
        stripeExternalAccount: {
          account_number: '',
          routing_number: '',
          account_holder_name: '',
        },
      }
      this.externalAccountMode = 'add'
      this.showSidebarExternalAccount = true
    },

    editExternalAccount(id) {
      this.externalAccountMode = 'edit'
      this.organizationExternalAccountLoading = true
      fetchOrganizationExternalAccountRequest(this.organizationId, id)
        .then(response => {
          this.externalAccount = response.data
          this.showSidebarExternalAccount = true
        })
        .finally(() => {
          this.organizationExternalAccountLoading = false
        })
    },

    saveExternalAccount(externalAccount) {
      if (this.externalAccountMode === 'add') {
        this.organizationExternalAccountLoading = true
        stripe
          .then(stripeResponse => {
            const newExternalAccount = {
              ...externalAccount.stripeExternalAccount,
              country: externalAccount.externalAccount.countryCode,
              currency: externalAccount.externalAccount.currencyCode,
            }
            newExternalAccount.currency === 'EUR' && delete newExternalAccount.routing_number
            stripeResponse.createToken('bank_account', newExternalAccount).then(response => {
              if (response.error) {
                this.organizationExternalAccountLoading = false
                return this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.error.code,
                    text: response.error.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
              return postOrganizationExternalAccountRequest(
                this.organizationId,
                {
                  externalAccountToken: response.token.id,
                  defaultForCurrency: externalAccount.externalAccount.defaultForCurrency,
                },
                externalAccount.bankName,
              )
                .then(() => {
                  this.organizationExternalAccountLoading = false
                  this.showSidebarExternalAccount = false
                  this.resetExternalAccount()
                  this.organizationExternalAccountsLoading = true
                  this.fetchOrganizationExternalAccounts({ refresh: true, organizationId: this.organizationId })
                })
                .catch(err => {
                  if (err) {
                    this.showSidebarExternalAccount = false
                    this.resetExternalAccount()
                  }
                })
            })
          })
          .catch(err => {
            if (err) {
              this.showSidebarExternalAccount = false
              this.resetExternalAccount()
            }
          })
      }

      if (this.externalAccountMode === 'edit') {
        this.organizationExternalAccountLoading = true
        patchOrganizationExternalAccountRequest(
          this.organizationId,
          {
            id: externalAccount.externalAccount.id,
            defaultForCurrency: externalAccount.externalAccount.defaultForCurrency,
            accountHolderName: externalAccount.stripeExternalAccount.account_holder_name,
          },
          externalAccount.bankName,
        )
          .then(() => {
            this.organizationExternalAccountLoading = false
            this.showSidebarExternalAccount = false
            this.resetExternalAccount()
            this.organizationExternalAccountsLoading = true
            this.fetchOrganizationExternalAccounts({ refresh: true, organizationId: this.organizationId })
          })
          .catch(err => {
            if (err) {
              this.showSidebarExternalAccount = false
              this.resetExternalAccount()
            }
          })
      }
    },

    resetExternalAccount() {
      this.externalAccountMode = ''
      this.organizationExternalAccountsLoading = false
      this.organizationExternalAccountLoading = false
      this.externalAccount = null
    },
  },

  beforeRouteLeave(to, from, next) {
    next()
  },
}
</script>
