<template>
  <section>
    <validation-observer tag="form" @submit.prevent="validationForm">

      <!--HEADER-->
      <div class="d-flex align-items-center justify-content-between mb-1 mb-md-0">
        <h2 class="mb-0">{{ $t('organization.airports') }}</h2>
        <app-btn-add :disabled="airportOptionsFiltered.length < 1" @click="repeatAgain" />
      </div>

      <div class="d-flex mb-1 align-items-center justify-content-between">
        <span>{{ $t('organization.airports_description') }}</span>
      </div>

      <!--LIST-->
      <b-overlay :show="loadOrganizationAirports" no-fade>
        <b-card class="border" style="max-height: 70vh; overflow-y: auto; overflow-x: hidden">
          <b-form ref="form" @submit.prevent="repeatAgain">

            <!-- Row Loop -->
            <b-row
              v-for="(organizationAirport, index) in organizationAirports"
              :key="index"
              ref="rowLocation"
              class="d-flex align-items-center pt-50"
            >
              <b-col v-if="index" cols="12">
                <hr />
              </b-col>

              <b-col md="6" class="mb-sm-50">
                <app-select
                  id="customerAirports"
                  v-model="organizationAirport.airport"
                  :options="airportOptionsFiltered"
                  :label-field="$t('common.airport')"
                  :clearable="false"
                  paginated
                  required
                  searchable
                  :per-page="airportsNumberOfItemsPerPage"
                  :overlay="airportsLoading"
                  :total-items="airportsTotalItems"
                  :last-page="airportsLastPage"
                  :next-page="airportsNextPage"
                  :previous-page="airportsPreviousPage"
                  :get-option-label="airport => airport.icaoCode ? airport.icaoCode + ' - ' + airport.name : airport.name"
                  @search="
                    search => {
                      airportsLoading = true
                      searchAirports(search)
                    }
                  "
                  @option:selected="APIFetchAirports"
                  @click:previous-page="goPreviousPage"
                  @click:next-page="goNextPage"
                />
              </b-col>

              <b-col cols="" class="mt-25 d-flex justify-content-center justify-content-between">
                <b-form-checkbox
                  v-model="organizationAirport.active"
                  :disabled="airportSwitchActiveDisabled"
                  name="check-button"
                  switch
                  inline
                >
                  {{ $t('common.active') }}
                </b-form-checkbox>

                <!-- Remove button -->
                <b-button-group>
                  <b-button v-ripple.400 size="md" variant="outline-primary" @click="confirmDeleteAlert(index)">
                    <font-awesome-icon
                      v-b-tooltip.hover.bottom.v-danger
                      icon="trash"
                      class="text-danger"
                      :title="$t('action.delete')"
                    />
                  </b-button>
                </b-button-group>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-overlay>
      <app-form-wizard-footer
        class="mt-3"
        save
        previous
        save-next
        @click:previous="$emit('previous-tab')"
        @click:save-next="nextRedirection = true"
      />

    </validation-observer>
  </section>
</template>

<script>
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { mapState } from 'vuex'

import AppBtnAdd from '@/components/AppBtnAdd.vue'
import {
  fetchOrganizationAirportsRequest,
  patchOrganizationAirportsRequest,
} from '@/request/globalApi/requests/organizationRequests'
import { fetchAirportsRequest } from '@/request/globalApi/requests/airportRequests'
import AppFormWizardFooter from '@/components/AppFormWizardFooter.vue'
import AppSelect from '@/components/AppSelect.vue'

export default {
  name: 'Airport',
  components: {
    AppBtnAdd,
    AppFormWizardFooter,
    AppSelect,
  },
  props: {
    airportSwitchActiveDisabled: {
      type: Boolean,
      default: false,
    },
    organizationId: {
      type: [String, Number],
      required: true,
      default: '',
    },
  },
  data() {
    return {
      loadOrganizationAirports: false,
      organizationAirports: [],
      nextRedirection: false,
      blankAirport: {
        airport: {
          id: null,
          name: '',
        },
        active: true,
      },
      airportOptions: [],
      airportsSearchQuery: null,
      airportsLoading: true,
      airportsNumberOfItemsPerPage: 8,
      airportsPage: 1,
      airportsTotalItems: 0,
      airportsFirstPage: 0,
      airportsLastPage: 0,
      airportsNextPage: 0,
      airportsPreviousPage: 0,
    }
  },
  computed: {
    ...mapState('auth', ['selectedOrganization']),
    airportOptionsFiltered() {
      const airportNameSelected = this.organizationAirports.map(organizationAirport => organizationAirport.airport.name)
      return this.airportOptions.filter(airport => !airportNameSelected.includes(airport.name))
    },
  },
  watch: {
    organizationAirports: {
      handler() {
        const airportId = this.organizationAirports.map(airport => airport.airport.id)
        this.$emit('editedAirports', airportId)
      },
      deep: true,
    },
  },
  mounted() {
    this.APIFetchOrganizationAirports()
    this.APIFetchAirports()
  },
  methods: {
    APIFetchOrganizationAirports() {
      this.loadOrganizationAirports = true
      fetchOrganizationAirportsRequest(this.organizationId, { getAllItems: true }).then(response => {
        const { organizationAirports } = response.data
        this.organizationAirports = organizationAirports
        this.loadOrganizationAirports = false
      })
    },
    APIFetchAirports() {
      fetchAirportsRequest({
        numberOfItemsPerPage: this.airportsNumberOfItemsPerPage,
        page: this.airportsPage,
        searchQuery: this.airportsSearchQuery,
      }).then(response => {
        this.airportOptions = []
        const { airports } = response.data
        airports.forEach(airportData => {
          const airport = airportData
          airport.value = airport.name ? airport.name : airport.id
          airport.label = airport.value
          this.airportOptions.push(airport)
        })
        this.airportsTotalItems = response.data.totalItems
        this.airportsFirstPage = response.data.firstPage
        this.airportsLastPage = response.data.lastPage
        this.airportsNextPage = response.data.nextPage
        this.airportsPreviousPage = response.data.previousPage
        this.airportsLoading = false
      })
    },
    searchAirports(search) {
      this.airportsPage = 1
      this.airportsSearchQuery = search
      this.APIFetchAirports()
    },
    goNextPage() {
      this.airportsPage = this.airportsNextPage
      this.APIFetchAirports()
    },
    goPreviousPage() {
      this.airportsPage = this.airportsPreviousPage
      this.APIFetchAirports()
    },
    APIEditOrganizationAirport(organizationAirports) {
      const organizationAirportObj = { organizationAirports }
      return patchOrganizationAirportsRequest(this.organizationId, organizationAirportObj)
        .then(() => true)
        .catch(() => false)
    },
    validationForm() {
      const organizationAirportsToSend = []
      this.organizationAirports.forEach(organizationAirport => {
        organizationAirportsToSend.push({
          airportId: organizationAirport.airport.id,
          airportName: organizationAirport.airport.name,
          active: organizationAirport.active,
        })
      })
      this.APIEditOrganizationAirport(organizationAirportsToSend)
        .then(() => {
          if (this.nextRedirection) {
            this.$emit('next-tab')
            this.nextRedirection = false
          }
          return true
        })
    },
    confirmDeleteAlert(index) {
      if (!this.organizationAirports[index].airport.id) this.organizationAirports.splice(index, 1)
      else {
        this.$bvModal
          .msgBoxConfirm(this.$t('alert.delete_confirmation.message'), {
            title: this.$t('alert.delete_confirmation.title'),
            size: 'sm',
            okVariant: 'primary',
            okTitle: this.$t('common.yes'),
            cancelTitle: this.$t('common.no'),
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(isConfirmed => {
            if (isConfirmed) {
              this.alert(this.$t('alert.delete.success'))
              this.organizationAirports.splice(index, 1)
            }
          })
      }
    },
    refetchData() {
      this.APIFetchOrganizationAirports()
    },
    repeatAgain() {
      if (this.airportOptionsFiltered.length) {
        this.organizationAirports.push({
          airport: {
            name: this.$t('navigation.trip.airport'),
          },
          active: true,
        })
      }
    },
  },
}
</script>
