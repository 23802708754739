var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"external-accounts"}},[_c('b-card',{staticClass:"mb-0",attrs:{"no-body":""}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-button',{on:{"click":function($event){return _vm.redirectKycAccountLink()}}},[_vm._v(" "+_vm._s(_vm.$t('organization.kyc.account_link'))+" ")])],1)],1),_c('app-data-table',{ref:"external-accounts-table",staticClass:"mx-n2",attrs:{"table-name":"external-accounts-table","primary-key":"id","fields":_vm.organizationExternalAccountsFields,"items":_vm.organizationExternalAccounts,"busy":_vm.organizationExternalAccountsLoading,"page":_vm.page,"per-page":_vm.numberOfItemsPerPage,"manual-pagination":"","top-table":{
        showSearch: false,
        showAdd: _vm.$can('ORGANIZATION_ACCOUNT_EDIT'),
        disabled: _vm.organizationExternalAccountsLoading,
      },"actions-table":{
        showEdit: _vm.$can('ORGANIZATION_ACCOUNT_EDIT'),
        showDelete: function (item) { return _vm.$can('ORGANIZATION_ACCOUNT_DELETE') && !item.defaultForCurrency; },
      },"bottom-table":{ totalCount: _vm.pagination.totalItems }},on:{"table-select-per-page":function($event){;(_vm.numberOfItemsPerPage = $event), _vm.fetchOrganizationExternalAccounts({ refresh: true, organizationId: this.organizationId })},"table-add":function($event){return _vm.addExternalAccount()},"edit":function($event){return _vm.editExternalAccount($event.item.id)},"delete":function($event){return _vm.deleteOrganizationExternalAccount($event.item)},"pagination":function($event){;(_vm.page = $event), _vm.fetchOrganizationExternalAccounts({ refresh: true, organizationId: this.organizationId })}},scopedSlots:_vm._u([{key:"cell(lastCharacters)",fn:function(data){return [_vm._v(" "+_vm._s(("**** " + (data.item.lastCharacters)))+" ")]}},{key:"cell(defaultForCurrency)",fn:function(data){return [(data.item.defaultForCurrency)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('common.yes')))]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('common.no')))])]}},{key:"cell(status)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("enumTranslate")(data.item.status,'external_account_status'))+" ")]}}])})],1),_c('app-form-wizard-footer',{staticClass:"mt-3 mx-n1",attrs:{"next":"","previous":""},on:{"click:previous":function($event){return _vm.$emit('previous-tab')},"click:next":function($event){return _vm.$emit('next-tab')}}}),_c('SidebarExternalAccount',{attrs:{"show-sidebar-external-account":_vm.showSidebarExternalAccount,"external-account-mode":_vm.externalAccountMode,"external-account":_vm.externalAccount,"external-account-loading":_vm.organizationExternalAccountLoading},on:{"editionUpdated":function($event){_vm.showSidebarExternalAccount = $event},"externalAccountUpdated":function($event){return _vm.saveExternalAccount($event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }