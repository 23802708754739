<template>
  <div id="organization-accounting">
    <b-card class="mb-0" no-body>
      <div class="m-1">
        <!-- Table Top -->

        <b-row class="accountingActions">
          <b-col class="d-md-flex pb-1 justify-content-md-start" cols="12" md="6">
            <b-button @click="isEditInvoiceConfigurationSidebarActive = true">
              <font-awesome-icon class="mr-50" icon="wrench" />
              {{ $t('organization.accounting.invoice_configuration.title') }}
            </b-button>
          </b-col>

          <!--<b-col class="d-md-flex pb-1 justify-content-md-end" cols="12" md="6">
            <b-button disabled>
              <font-awesome-icon class="mr-50" icon="file-pdf" />
              {{ $t('action.export') }}
            </b-button>
          </b-col>-->
        </b-row>
        <b-row>
          <!-- Per Page -->
          <b-col class="d-flex align-items-center justify-content-start pb-1 mb-md-0" cols="12" lg="1" md="2">
            <b-form-select
              v-model="numberOfItemsPerPage"
              v-b-tooltip.hover.top="$t('action.per_page')"
              :disabled="organizationAccountingLoading"
              :options="dataTable.parPageOption"
              class="per-page-selector"
              @change="dispatchFetchOrganizationAccounting({ refresh: true })"
            />
            <!--            <label class="d-none d-lg-block">{{ $tc('pagination.entries', numberOfItemsPerPage) }}</label>-->
          </b-col>

          <!--DATE START-->
          <b-col class="pb-1" cols="12" lg="3" md="5">
            <b-form-datepicker id="startAt" v-model="dateAfter" :disabled="organizationAccountingLoading" :placeholder="$t('common.start_at')" reset-button />
          </b-col>
          <!--DATE END-->
          <b-col class="pb-1" cols="12" lg="3" md="5">
            <b-form-datepicker
              id="endAt"
              v-model="dateBefore"
              :disabled="organizationAccountingLoading"
              :placeholder="$t('common.end_at')"
              clearable="true"
              reset-button
            />
          </b-col>
          <!-- Search and action -->
          <b-col class="pb-1" cols="12" lg="5">
            <div class="d-flex align-items-center justify-content-end">
              <b-input-group>
                <b-form-input
                  v-model="searchQuery"
                  :placeholder="$t('action.search')"
                  @keydown.enter="search()"
                  @keyup.backspace="() => searchQuery === '' && search()"
                />
                <b-input-group-append>
                  <b-button
                    id="quote-search"
                    :disabled="searchQuery === '' || organizationAccountingLoading"
                    size="sm"
                    @click="dispatchFetchOrganizationAccounting({ refresh: true })"
                  >
                    <feather-icon icon="SearchIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </div>
          </b-col>
        </b-row>
      </div>
      <!-- TABLE -->
      <b-table
        ref="organization-accounting-table"
        :busy="organizationAccountingLoading"
        :empty-text="$t('common.no_record_found')"
        :fields="organizationAccountingFields"
        :items="organizationAccounting"
        :sort-by.sync="sort.sortBy"
        :sort-desc.sync="sort.sortDesc"
        class="position-relative table-dropdown-action"
        primary-key="id"
        responsive
        show-empty
        @sort-changed="updateSort"
      >
        <!-- LOADING -->
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle" />
          </div>
        </template>
        <!-- HEAD -->
        <template #head(reference)>
          {{ $t('organization.accounting.reference') }}
        </template>
        <template #head(date)>
          {{ $t('organization.accounting.date') }}
        </template>
        <template #head(totalQuotesExcludingTaxes)>
          {{ $t('organization.accounting.total_ht') }}
        </template>
        <template #head(totalQuotesIncludingTaxes)>
          {{ $t('organization.accounting.total_ttc') }}
        </template>
        <template #head(priceExcludingTaxes)>
          {{ $t('organization.accounting.commission_ht') }}
        </template>
        <template #head(priceIncludingTaxes)>
          {{ $t('organization.accounting.commission_ttc') }}
        </template>
        <template #head(state)>
          {{ $t('common.status') }}
        </template>
        <template #head(actions)>
          {{ $tc('action.title', 2) }}
        </template>
        <!-- CELL -->
        <template #cell(date)="data">
          <span class="text-nowrap">{{
            new Date(data.item.date).toLocaleDateString($i18n.locale, {
              year: 'numeric',
              month: 'short',
              day: '2-digit',
              weekday: 'short',
            })
          }}</span>
        </template>
        <template #cell(state)="data">
          <b-badge :variant="`light-${resolveInvoiceStateVariant(data.item.state)}`" class="text-capitalize" pill>
            {{ $t(resolveInvoiceStatusText(data.item.state)) }}
          </b-badge>
        </template>
        <template #cell(actions)="data">
          <b-dropdown no-caret variant="link">
            <template #button-content>
              <feather-icon class="align-middle text-body" icon="MoreVerticalIcon" size="16" />
            </template>
            <!--view-->
            <b-dropdown-item v-if="$can('ACCOUNTING_VIEW')" @click.prevent="$router.push({ name: 'invoiceAccountingShow', params: { id: data.item.id } })">
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">{{ $t('action.show') }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <!--        PAGINATION-->
      <div class="mx-2 mb-2">
        <b-row>
          <!-- Items -->
          <b-col class="d-flex align-items-center justify-content-center justify-content-sm-start" cols="12" sm="6">
            <app-data-table-entries-display
              :items-length="organizationAccounting.length"
              :page="page"
              :per-page="numberOfItemsPerPage"
              :total-count="pagination.totalItems"
            />
          </b-col>
          <!-- Pagination -->
          <b-col class="d-flex align-items-center justify-content-center justify-content-sm-end" cols="12" sm="6">
            <b-pagination
              v-model="page"
              :per-page="numberOfItemsPerPage"
              :total-rows="pagination.totalItems"
              align="end"
              class="mb-0 mt-1 mt-sm-0"
              first-number
              last-number
              next-class="next-item"
              prev-class="prev-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <app-form-wizard-footer class="mt-3" next previous @click:previous="$emit('previous-tab')" @click:next="$emit('next-tab')" />

    <!--SIDEBAR-->
    <sidebar-invoice-configuration
      :is-edit-invoice-configuration-sidebar-active.sync="isEditInvoiceConfigurationSidebarActive"
      :operator="operator"
      :organization-id="selectedOrganization.id"
      :seller="seller"
      @refetch-data="dispatchFetchOrganizationAccounting({ refresh: true })"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import AppDataTableEntriesDisplay from '@/components/AppDataTableEntriesDisplay.vue'
import SidebarInvoiceConfiguration from '@/views/organization/sidebar/SidebarInvoiceConfiguration.vue'
import UIHelper from '@/helpers/ui'
import AppFormWizardFooter from '@/components/AppFormWizardFooter.vue'

export default {
  name: 'OrganizationAccounting',
  components: {
    AppDataTableEntriesDisplay,
    SidebarInvoiceConfiguration,
    AppFormWizardFooter,
  },

  mixins: [UIHelper],
  props: {
    seller: {
      type: Boolean,
      default: false,
    },
    operator: {
      type: Boolean,
      default: false,
    },
    organizationId: {
      type: [String, Number],
      required: true,
      default: '',
    },
  },
  data() {
    return {
      organizationAccountingFields: [
        { key: 'reference', sortable: true },
        { key: 'date', sortable: true },
        { key: 'totalQuotesExcludingTaxes' },
        { key: 'totalQuotesIncludingTaxes' },
        { key: 'priceExcludingTaxes' },
        { key: 'priceIncludingTaxes' },
        { key: 'state', sortable: true },
        { key: 'actions', thClass: 'text-right', tdClass: 'text-right' },
      ],
      sort: {
        sortBy: '',
        sortDesc: '',
      },
      isEditInvoiceConfigurationSidebarActive: false,
    }
  },

  computed: {
    ...mapState('organizationAccounting', ['organizationAccountingLoading', 'organizationAccounting', 'pagination', 'queryParams', 'sorting']),
    ...mapState('appConfig', ['dataTable']),
    ...mapState('auth', ['selectedOrganization']),
    ...mapGetters('organizationAccounting', ['searchFieldOptions']),
    ...mapFields('organizationAccounting', [
      'queryParams.page',
      'queryParams.numberOfItemsPerPage',
      'queryParams.searchQuery',
      'queryParams.dateAfter',
      'queryParams.dateBefore',
      'searchField',
    ]),
    search: {
      get() {
        return this.searchText
      },
      set(value) {
        this.setSearch(value)
      },
    },
  },

  watch: {
    queryParams: {
      handler() {
        this.dispatchFetchOrganizationAccounting({ refresh: false })
      },
      deep: false,
    },

    sort: {
      handler(value) {
        this.setSort(value)
      },
      deep: true,
    },
  },

  mounted() {
    let refresh = false
    if (this.$route.params.refresh) {
      refresh = this.$route.params.refresh
    }

    this.dispatchFetchOrganizationAccounting({ refresh })
  },

  methods: {
    ...mapActions('organizationAccounting', ['fetchOrganizationAccounting', 'clearOrganizationAccountingShowed', 'setSort', 'setSearch']),

    updateSort(e) {
      this.sortBy = e.sortBy
      this.sortDesc = e.sortDesc
      this.setSort({ sortBy: e.sortBy, sortDesc: e.sortDesc })
    },

    dispatchFetchOrganizationAccounting({ refresh }) {
      this.$store.dispatch('organizationAccounting/fetchOrganizationAccounting', { refresh, organizationId: this.organizationId })
    },
  },

  beforeRouteLeave(to, from, next) {
    this.clearOrganizationAccountingShowed()
    next()
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 65px;
}

@media (max-width: 992px) {
  .accountingActions div button,
  .per-page-selector {
    width: 100%;
  }
}
</style>
