<template>
  <section>
    <div>
      <!-- Page: Informations -->
      <div class="d-flex" :show="loadOrganizationOffer">
        <h4 class="mb-1">
          {{ $t('organization.offer.informations.title') }}
        </h4>
      </div>

      <!-- Header: Informations-->
      <b-row class="mb-1">
        <b-col cols="12" md="6">
          <!-- Header: Company-->
          <div>
            <h5>
              {{ $t('organization.offer.informations.company.title') }}
            </h5>
            <ul class="list-unstyled">
              <li>
                {{ $t('organization.offer.informations.company.company_name') }} :
                {{ company.name }}
              </li>
              <li
                v-if="company.isFo"
              >
                {{ $t('organization.offer.informations.company.number_of_aircrafts') }} :
                {{ company.numberOfAircrafts }}
              </li>
              <li
                v-if="company.isFo"
              >
                {{ $t('organization.offer.informations.company.number_of_aoc') }} :
                {{ company.numberOfAoc }}
              </li>
              <li
                v-if="company.isFo"
              >
                {{ $t('organization.offer.informations.company.aoc_countries') }} :
                <ul>
                  <li v-for="country in company.aocCountriesNames" :key="country">
                    {{ country }}
                  </li>
                </ul>
              </li>
              <li>
                {{ $t('organization.offer.informations.company.main_typology') }} :
                <span v-if="company.typology">
                  {{ company.typology.name | trans }}
                </span>
              </li>
              <li v-if="!company.isFo">
                {{ $t('organization.offer.informations.company.other_typologies') }} :
                <ul v-if="company.otherTypologies">
                  <li v-for="typology in company.otherTypologies" :key="typology.name | trans">
                    {{ typology.name | trans }}
                  </li>
                </ul>
              </li>
              <li>
                {{ $t('organization.offer.informations.company.country') }} : {{ company.address.countryName }}
                <!-- to do gérer pays-->
              </li>
              <li>{{ $t('organization.offer.informations.company.main_location_address') }} : {{
                company.address.address
              }} {{ company.address.city }}
              </li>
              <li>
                {{
                  company.isFo ? $t('organization.offer.informations.company.fleet_bases_airports') : $t('organization.offer.informations.company.served_airports')
                }} :
                <ul>
                  <li v-for="{ airport } in company.airports" :key="airport.icaoCode || airport.name">
                    {{ airport.icaoCode }} - {{ airport.name }}
                  </li>
                </ul>
              </li>
              <li>
                {{ $t('organization.offer.informations.company.company_website') }} :
                {{ company.website }}
              </li>
              <li>
                {{ $t('organization.offer.informations.company.interested_account_creation') }} :
                {{ company.subsidiariesInterested ? $t('common.yes') : $t('common.no') }}
              </li>
              <li>
                {{ $t('organization.offer.informations.company.if_yes') }} :
                <ul>
                  <li v-for="country in company.subsidiariesCountries" :key="country.code || country.name">
                    {{ country.code }} - {{ country.name }}
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </b-col>

        <!-- Header: Contact-->
        <b-col cols="12" md="6">
          <h5>
            {{ $t('organization.offer.informations.contact.title') }}
          </h5>
          <ul class="list-unstyled">
            <li>
              {{ $t('organization.offer.informations.contact.first_name') }} :
              {{ contact.firstName }}
            </li>
            <li>
              {{ $t('organization.offer.informations.contact.last_name') }} :
              {{ contact.lastName }}
            </li>
            <li>
              {{ $t('organization.offer.informations.contact.email_adress') }} :
              {{ contact.email }}
            </li>
            <li>
              {{ $t('organization.offer.informations.contact.phone_number') }} :
              {{ contact.phone }}
            </li>
            <li>
              {{ $t('organization.offer.informations.contact.job_title') }} :
              {{ contact.job }}
            </li>
          </ul>
        </b-col>
      </b-row>

      <!-- Header: Offer -->
      <b-row class="pricing-card">
        <b-col cols="12">
          <b-card class="d-flex border">
            <div class="d-flex wrap">
              <b-col cols="6" class="pl-0 d-md-flex justify-content-start">
                <b-card-title class="ml-0">
                  {{ $t('organization.offer.current_offer') }}
                </b-card-title>
              </b-col>
              <!-- Offer Button -->
              <b-col
                v-if="$can('OPERATOR_ORGANIZATION_EDIT') && $route.params.id"
                cols="6"
                class="pr-0 d-flex justify-content-end"
              >
                <b-button class="mr-0" @click="isSubmitOfferSidebarActive = true">
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  {{ $t('action.submit') }}
                </b-button>
              </b-col>
            </div>
            <!-- Offer Body -->
            <div>
              <span v-if="!activeOffer" class="d-flex justify-content-center no-offer">
                {{ $t('organization.offer.no_offer') }}
              </span>
              <!-- Offer Body -->
              <div v-else-if="activeOffer.subscription" class="popular d-flex flex-wrap" align="left">
                <b-col cols="12" md="6" class="pl-0">
                  <!-- Offer Details -->
                  <div class="item-img d-flex flex-wrap align-items-center mb-1 annual-plan">
                    <feather-icon icon="SendIcon" size="50" />
                    <p class="ml-1 mb-0 plan-price">
                      <span id="offer-price"> {{ activeOffer.subscription.totalDisplayPrice | priceFormat }} </span>
                      <sub> {{ $t(`registration.offers.${activeOffer.subscription.paymentFrequency}`) }} </sub>
                    </p>
                  </div>
                  <div class="item-img pt-0">
                    <h4>
                      {{ activeOffer.reference | trans }}
                    </h4>
                    <p class="py-0 my-0">
                      {{ $t('organization.offer.acceptedOffer') }} :
                      {{ activeOffer.startAt | dateFormatUtc }}
                    </p>
                    <p class="py-0 my-0 font-italic">
                      {{ $t('registration.offers.duration') }} :
                      {{ $t(`registration.offers.${activeOffer.duration}`) }}
                      <span v-if="activeOffer.reconductible">( {{ $t('offer.subscription.reconduction') }} )</span>
                    </p>
                  </div>
                </b-col>
                <b-col cols="12" md="6" class="pl-0">
                  <h4 class="mt-1 mb-0"> {{ $t('offer.subscription.description') }} </h4>
                  <!-- eslint-disable-next-line vue/no-v-html -->
                  <div v-html="$options.filters.trans(activeOffer.description)" />
                  <ul class="list-group-circle text-left py-0 pl-2  mb-0">
                    <li v-for="description in activeOffer.subscription.descriptions" :key="description.id" class="py-0">
                      {{ description.description | trans }}
                    </li>
                  </ul>
                </b-col>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>

      <!-- Header: Payment -->
      <b-row>
        <b-col cols="12">
          <b-card class="border d-flex">
            <div class="d-flex wrap">
              <b-col cols="6" class="pl-0 d-md-flex justify-content-start">
                <b-card-title>
                  {{ $t('organization.offer.payment') }}
                </b-card-title>
              </b-col>

              <!-- Payment Select year -->
              <!-- ToDo : update table with Selected year -->
              <b-col cols="6" class="pr-0 d-flex justify-content-end">
                <b-form-group :label="$t('organization.offer.year')" label-for="organizationPayment-date" style="min-width: 100px">
                  <v-select id="years" v-model="params.date" :options="years" label="text" :clearable="false" />
                </b-form-group>
              </b-col>
            </div>

            <!--Payment table-->
            <b-overlay :show="loadOrganizationPayments" no-fade>
              <b-table
                ref="refPaymentListTable"
                class="position-relative"
                :items="organizationPayments"
                responsive
                :fields="tableColumns"
                primary-key="id"
                :sort-by.sync="params.sortBy"
                :sort-desc.sync="params.sortDesc"
                show-empty
                :empty-text="$t('organization.offer.no_payment')"
              >
                <!-- Column: Offer Reference -->
                <template #cell(offerReference)="data">
                  <span>
                    {{ data.item.offerReference | trans }}
                  </span>
                </template>

                <!-- Column: Date -->
                <template #cell(date)="data">
                  <span class="text-nowrap">
                    {{ data.item.date | dateFormatUtc }}
                  </span>
                </template>

                <!-- Column: Amount -->
                <template #cell(amount)="data">
                  <span> {{ data.item.amount | priceFormat }}</span>
                </template>

                <!-- Column: Status -->
                <template #cell(state)="data">
                  <b-badge
                    pill
                    :variant="`light-${resolveInvoiceStateVariant(data.item.state)}`"
                    class="text-capitalize"
                  >
                    {{ data.item.state | enumTranslate('invoice_state') }}
                  </b-badge>
                </template>

                <!-- Column: Actions -->
                <template #cell(actions)="data">
                  <b-dropdown variant="link" no-caret>
                    <template #button-content>
                      <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                    </template>
                    <!--view-->
                    <b-dropdown-item
                      v-if="$can('ACCOUNTING_VIEW')"
                      @click.prevent="$router.push({ name: 'orderAccountingShow', params: { id: data.item.quoteId } })"
                    >
                      <feather-icon icon="EyeIcon" />
                      <span class="align-middle ml-50">{{ $t('action.show') }}</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
              </b-table>
            </b-overlay>
          </b-card>
        </b-col>
      </b-row>

      <!-- Header: History -->
      <b-row>
        <b-col cols="12">
          <b-card class="border">
            <b-card-title>
              {{ $t('organization.offer.history') }}
            </b-card-title>
            <span v-if="logOffers.length === 0" class="d-flex justify-content-center font-weight-bold no-history">
              {{ $t('organization.offer.no_history') }}
            </span>

            <div v-for="offer in logOffers" v-else :key="offer.date || offer.event" class="pb-1 border-left-secondary">
              <div class="row">
                <div class="row no-gutters col justify-content-center">
                  <div
                    v-if="offer.event === 'accepted'"
                    class="bullet bullet-lg border-secondary bullet-success row"
                  ></div>
                  <div v-else class="bullet bullet-lg border-secondary bullet-warning row"></div>
                  <!-- ToDo : modify - dynamique -->

                  <div class="col-12">
                    <span class="mx-2">
                      {{
                        new Date(offer.date).toLocaleDateString($i18n.locale, {
                          year: 'numeric',
                          month: 'short',
                          day: '2-digit',
                        })
                      }}
                      - {{ $t('organization.offer.offer') }} {{ offer.event }}
                    </span>
                  </div>

                  <div
                    v-for="eachOffer in offer.offers"
                    :key="eachOffer.reference[$store.state.appConfig.lang] || eachOffer.validityDate"
                    class="col-12"
                  >
                    <span class="mx-1"> > </span>
                    {{ eachOffer.reference | trans }}
                  </div>
                </div>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>

    <app-form-wizard-footer class="mt-3" previous @click:previous="$emit('previous-tab')" />

    <!--SIDEBAR-->
    <sidebar-submit-offer
      v-if="$can('OPERATOR_SUBSCRIPTION_EDIT')"
      :is-submit-offer-sidebar-active.sync="isSubmitOfferSidebarActive"
      :organization-id="selectedOrganization.id"
      @refetch-data="refetchData"
    />
  </section>
</template>

<script>
import { mapState } from 'vuex'
import UIHelper from '@/helpers/ui'
import SidebarSubmitOffer from '@/views/organization/sidebar/SidebarSubmitOffer.vue'
import {
  fetchOrganizationOfferRequest,
  fetchOrganizationPaymentsRequest,
} from '@/request/globalApi/requests/organizationRequests'
import AppFormWizardFooter from '@/components/AppFormWizardFooter.vue'

export default {
  name: 'Offer',

  components: {
    SidebarSubmitOffer,
    AppFormWizardFooter,
  },
  mixins: [UIHelper],
  props: {
    organizationId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      isSubmitOfferSidebarActive: false,
      contact: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        job: '',
      },
      company: {
        name: '',
        structure: '',
        typology: '',
        otherTypologies: [],
        numberOfAircrafts: 0,
        numberOfAoc: 0,
        aocCountriesNames: [],
        country: '',
        address: '',
        airports: [],
        website: '',
        subsidiariesInterested: '',
        subsidiariesCountries: [],
        isFo: false,
        isFbo: false,
      },
      activeOffer: '',
      logOffers: [],
      params: {
        page: null,
        numberOfItemsPerPage: null,
        getAllItems: true,
        offerReference: null,
        invoiceState: null,
        offerReferenceOrder: null,
        dateOrder: 'asc',
        amountOrder: null,
        invoiceStateOrder: null,
        sortBy: null,
        sortDesc: null,
        // SEARCH
        date: new Date().getFullYear(),
        amount: null,
      },

      loadOrganizationOffer: false,
      loadOrganizationPayments: false,
      organizationPayments: [],
      years: [],
      getYearTab: true,
      tableColumns: [
        { key: 'offerReference', sortable: true, label: this.$t('organization.offer.offer_reference') },
        { key: 'date', sortable: true, label: this.$t('organization.offer.payment_date') },
        { key: 'amount', sortable: true, label: this.$t('organization.offer.amount') },
        { key: 'state', sortable: true, label: this.$t('organization.offer.state') },
        { key: 'actions', sortable: false, tdClass: 'actionCol', label: this.$t('common.actions') },
      ],
    }
  },
  computed: {
    ...mapState('auth', ['selectedOrganization']),
    allFilters() {
      return `
       ${this.params.offerReference}|${this.params.date}|${this.params.amount}
       |${this.params.state}`
    },
  },
  watch: {
    allFilters: {
      handler() {
        this.refetchData()
      },
      deep: true,
    },
  },
  mounted() {
    this.APIFetchOrganizationPayments()
    this.APIFetchOrganizationOffer()
  },
  methods: {
    APIFetchOrganizationOffer() {
      this.loadOrganizationOffer = true
      const organizationId = this.$route.params.id || this.selectedOrganization.id

      fetchOrganizationOfferRequest(organizationId).then(response => {
        const {
          emergencyContactFirstName,
          emergencyContactLastName,
          emergencyContactEmail,
          emergencyContactPhone,
          emergencyContactJobTitle,
        } = response.data
        this.contact.firstName = emergencyContactFirstName
        this.contact.lastName = emergencyContactLastName
        this.contact.email = emergencyContactEmail
        this.contact.phone = emergencyContactPhone
        this.contact.job = emergencyContactJobTitle

        const {
          airports,
          defaultAddress,
          legalName,
          mainTypology,
          otherTypologies,
          numberOfAircrafts,
          numberOfAoc,
          aocCountriesNames,
          typologies,
          websiteUrl,
          isFo,
          isFbo,
        } = response.data
        this.company.name = legalName
        this.company.structure = typologies
        this.company.typology = mainTypology
        this.company.otherTypologies = otherTypologies
        this.company.numberOfAircrafts = numberOfAircrafts
        this.company.numberOfAoc = numberOfAoc
        this.company.aocCountriesNames = aocCountriesNames
        this.company.address = defaultAddress
        this.company.airports = airports
        this.company.website = websiteUrl
        this.company.isFo = isFo
        this.company.isFbo = isFbo

        const { offer, offerLogs } = response.data
        this.activeOffer = offer
        this.logOffers = offerLogs

        const { otherSubsidiariesInterested, subsidiariesCountries } = response.data
        this.company.subsidiariesInterested = otherSubsidiariesInterested
        this.company.subsidiariesCountries = subsidiariesCountries

        this.loadOrganizationOffer = false
      })
    },
    APIFetchOrganizationPayments() {
      const organizationId = this.$route.params.id || this.selectedOrganization.id

      this.loadOrganizationPayments = true
      fetchOrganizationPaymentsRequest(organizationId, this.params).then(response => {
        const { payments } = response.data
        this.organizationPayments = payments
        this.loadOrganizationPayments = false
        if (this.getYearTab === true) {
          this.organizationPayments.forEach(element => {
            const el = new Date(element.date).getFullYear()
            if (this.years.indexOf(el)) {
              this.years.push(el)
            }
          })
          this.years.push(2021)
          this.years.unshift(2019)
          this.years.sort().reverse()
          this.getYearTab = false
        }
      })
    },
    refetchData() {
      this.APIFetchOrganizationOffer()
      this.APIFetchOrganizationPayments()
      this.$refs.refPaymentListTable.refresh()
      if (this.params.date === null) {
        this.params.date = new Date().getFullYear()
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-pricing.scss';

#offer-price {
  font-size: 2rem !important;
}
</style>
