<template>
  <app-sidebar-form
    id="addressForm"
    form-name="address-form"
    :edition="value"
    :edition-mode-title="address.address.id ? 'edit' : 'add'"
    :external-request="onSubmit"
    :title="$t('common.address')"
    :entity="address"
    @update:edition="$emit('input', $event)"
  >
    <template #fields="data">
      <!-- NAME -->
      <app-input id="addressName" v-model="data.item.address.addressName" :label="$t('address.name')" />

      <!-- ADDRESS -->
      <app-input id="address" v-model="data.item.address.address" :label="$t('common.address')" required />

      <!-- ADDRESS Complementary-->
      <app-input id="complementaryAddress" v-model="data.item.address.complementaryAddress" :label="$t('address.complementary_address')" />

      <!-- Postalcode -->
      <app-input id="postalCode" v-model="data.item.address.postalCode" :label="$t('address.postal_code')" required />

      <!-- City -->
      <app-input id="city" v-model="data.item.address.city" :label="$t('address.city')" required />

      <!-- Country -->
      <enum-select
        v-model="data.item.address.countryCode"
        input-id="countryCode"
        enum-class="CountryCode"
        :label="$t('common.country')"
        text="text"
        :clearable="false"
        required
      />

      <!-- More info -->
      <validation-provider>
        <b-form-group :label="$t('address.more_informations')" label-for="moreInformation">
          <b-form-textarea id="moreInformation" v-model="data.item.address.additionalInformations" size="sm" :placeholder="$t('address.more_informations')" />
        </b-form-group>
      </validation-provider>

      <!-- Default address -->
      <validation-provider>
        <b-form-group label-for="codeBuilding">
          <b-form-checkbox v-model="data.item.default">
            {{ $t('address.default') }}
          </b-form-checkbox>
        </b-form-group>
      </validation-provider>
    </template>
  </app-sidebar-form>
</template>1

<script>
import { mapState } from 'vuex'

import AppSidebarForm from '@/components/AppSidebarForm.vue'
import { postOrganizationAddressRequest, patchOrganizationAddressRequest } from '@/request/globalApi/requests/organizationRequests'

export default {
  name: 'SidebarAddress',

  components: {
    AppSidebarForm,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    organizationId: {
      type: Number,
      default: null,
    },
    address: {
      type: Object,
      default: () => ({
        address: {
          addressName: null,
          address: null,
          complementaryAddress: null,
          postalCode: null,
          city: null,
          contactName: null,
          contactPhone: null,
          contactEmail: null,
          additionalInformations: null,
          buildingCode: null,
          countryCode: 'FR',
        },
        default: false,
      }),
    },
  },
  computed: { ...mapState('auth', ['selectedOrganization']) },
  methods: {
    async onSubmit(payload) {
      // ADD ADDRESS
      if (!payload.address.id) {
        return postOrganizationAddressRequest(this.selectedOrganization.id, payload).then(() => {
          this.resetValidator()
          this.$emit('refetch-data')
        })
      }
      // EDIT ADDRESS
      return patchOrganizationAddressRequest(this.selectedOrganization.id, payload)
        .then(() => {
          this.resetValidator()
          this.$emit('refetch-data')
        })
    },
    resetValidator() {
      requestAnimationFrame(() => {
        if (this.$refs.addAddressRules) {
          this.$refs.addAddressRules.reset()
        }
      })
    },
  },
}
</script>
