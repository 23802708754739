var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"sidebar-submit-offer","no-header":"","visible":_vm.isSubmitOfferSidebarActive},on:{"change":function (val) { return _vm.$emit('update:is-submit-offer-sidebar-active', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t('organization.offer.submit_offer'))+" ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"submitOffers",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"p-1",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('h4',[_vm._v(_vm._s(_vm.$t('organization.offer.offers')))]),_c('b-form-group',{attrs:{"label-for":"offerSubmission"}},[_c('v-select',{ref:"offerSelector",staticClass:"select-size-sm",attrs:{"input-id":"offerSubmission","options":_vm.allOffers,"label":"reference","placeholder":_vm.$t('organization.offer.select_offer'),"multiple":""},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var reference = ref.reference;
return [_vm._v(" "+_vm._s(_vm._f("trans")(reference))+" ")]}},{key:"selected-option",fn:function(ref){
var reference = ref.reference;
return [_vm._v(" "+_vm._s(_vm._f("trans")(reference))+" ")]}},{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('common.no_option_found'))+" ")]},proxy:true}],null,true),model:{value:(_vm.selectedOffers),callback:function ($$v) {_vm.selectedOffers=$$v},expression:"selectedOffers"}})],1)],1)],1),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{staticClass:"mr-2",attrs:{"type":"submit"}},[_c('font-awesome-icon',{staticClass:"mr-50",attrs:{"icon":"plus"}}),_vm._v(" "+_vm._s(_vm.$t('action.submit'))+" ")],1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-secondary"},on:{"click":hide}},[_c('font-awesome-icon',{staticClass:"mr-50",attrs:{"icon":"ban"}}),_vm._v(" "+_vm._s(_vm.$t('action.cancel'))+" ")],1)],1)],1)]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }